import React, { useState, useEffect } from "react";
import { Typography, InputLabel, Select, FormControl, CircularProgress, Snackbar, Alert, IconButton, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { UsersFetch } from "../components/Backend Routes/fetchAllUsers";
import { DeleteUsers } from '../components/Backend Routes/DeleteUsers';
import { UpdateUsers } from '../components/Backend Routes/UpdateUsers';
import CloseIcon from '@mui/icons-material/Close';

const AdminManagement = () => {
  const { users, loading, error, refetchUsers } = UsersFetch();
  const [organizationIdPairs, setOrganizationIdPairs] = useState([{ id: '', tier: '' }]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showEditMessage, setShowEditMessage] = useState(false);
  const [editedUser, setEditedUser] = useState({
    name: "",
    organization: "",
    organization_id: "",
    subscription_tier: "",
  });

  const formatOrganizationId = (organizationId) => {
    if (Array.isArray(organizationId)) {
      return organizationId
        .map((pair) => {
          const key = Object.keys(pair)[0];
          const value = pair[key];
          return `${key}: ${value}`;
        })
        .join(', ');
    }
    return '';
  };

  // const formatOrganizationId = (organizationId) => {
  //   if (Array.isArray(organizationId)) {
  //     return organizationId
  //       .map((pair) => {
  //         if (typeof pair === 'string') {
  //           return pair;
  //         } else {
  //           const key = Object.keys(pair)[0];
  //           const value = pair[key];
  //           return `${key}: ${value}`;
  //         }
  //       })
  //       .join(', ');
  //   }
  //   return '';
  // };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
    { field: "Name", headerName: "Name", flex: 1.5, minWidth: 200 },
    { field: "email", headerName: "Email", flex: 1.5, minWidth: 250 },
    { field: "organization", headerName: "Organization", flex: 1, minWidth: 150 },
    { field: 'organization_id', headerName: 'Organization ID : Subscription Tier', flex: 2.5, minWidth: 300 },
  ];

  useEffect(() => {
    if (selectedUsers.length === 1) {
      const user = users.find((user) => user === selectedUsers[0]);
      if (user) {
        setSelectedUser(user);
        setEditedUser({
          name: user.name,
          organization: user.organization,
          organization_id: user.organization_id,
        });
        
        // Set the organizationIdPairs state based on the user's organization_id
        if (Array.isArray(user.organization_id)) {
          const pairs = user.organization_id.map((pair) => {
            const key = Object.keys(pair)[0];
            const value = pair[key];
            return { id: key, tier: value };
          });
          setOrganizationIdPairs(pairs);
        } else {
          setOrganizationIdPairs([{ id: '', tier: '' }]);
        }
      }
    } else {
      setSelectedUser(null);
      setEditedUser({
        name: "",
        organization: "",
        organization_id: "",
      });
      setOrganizationIdPairs([{ id: '', tier: '' }]);
    }
  }, [selectedUsers, users]);

  const handleOrganizationIdChange = (index, field, value) => {
    const updatedPairs = [...organizationIdPairs];
    updatedPairs[index][field] = value;
  
    if (field === 'tier' && value === 'Premium User') {
      // Check if "Premium User" tier is already assigned to another organization_id
      const premiumUserIndex = updatedPairs.findIndex(
        (pair, i) => i !== index && pair.tier === 'Premium User'
      );
      if (premiumUserIndex !== -1) {
        // Change the tier of the other organization_id to "Basic"
        updatedPairs[premiumUserIndex].tier = 'Basic';
      }
    }
  
    setOrganizationIdPairs(updatedPairs);
  };

  const handleAddOrganizationIdRow = () => {
    setOrganizationIdPairs([...organizationIdPairs, { id: '', tier: '' }]);
  };

  const handleDeleteOrganizationIdRow = () => {
    if (organizationIdPairs.length > 1) {
      const updatedPairs = [...organizationIdPairs];
      updatedPairs.pop();
      setOrganizationIdPairs(updatedPairs);
    }
  };  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenEditPopup(true);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleSelectionChange = (selection) => {
    const selectedUserData = selection.map((rowIndex) => users[rowIndex-1]);
    setSelectedUsers(selectedUserData);
    console.log('Selected Users: ', selectedUserData);
  };

  const handleEditChange = (field, value) => {
    if (field === "organization_id") {
      setEditedUser((prevUser) => ({
        ...prevUser,
        [field]: value,
      }));
    } else {
      setEditedUser((prevUser) => ({
        ...prevUser,
        [field]: value,
      }));
    }
  };


  const handleEditSubmit = async () => {
    const organizationId = organizationIdPairs.reduce((acc, pair) => {
      if (pair.id && pair.tier) {
        acc.push({ [pair.id]: pair.tier });
      }
      return acc;
    }, []);

    // Check if only one organization_id has the "Premium User" tier
    const premiumUserCount = organizationId.filter(
      (pair) => Object.values(pair)[0] === 'Premium User'
    ).length;
    if (premiumUserCount > 1) {
      // Display an error message or handle the validation failure
      console.error('Only one organization_id can have the "Premium User" tier.');
      return;
    }

    try {
      await UpdateUsers(selectedUsers, { ...editedUser, organization_id: organizationId });
      // Show success message
      setShowEditMessage(true);
      // Close the confirmation dialog and update slates
      refetchUsers();
      setOpenDeleteConfirmation(false);
      setOpenEditPopup(false);
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error updating users:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    if (confirmDelete) {
      try {
        const databaseIds = selectedUsers.map((user) => user.database_id);
        console.log('databaseIds: ', databaseIds);
        await DeleteUsers(selectedUsers);
        // Show success message
        setShowDeleteMessage(true);
        // Close the confirmation dialog and update slates
        refetchUsers();
        setOpenDeleteConfirmation(false);
        setConfirmDelete(false);
        setSelectedUsers([]);
      } catch (error) {
        console.error("Error deleting users:", error);
      }
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowDeleteMessage(false);
    setShowEditMessage(false);
  };

  // Ensure that users is an array before mapping
  const rows = Array.isArray(users) ? users.map((user, index) => ({
    id: index + 1, // Assuming user IDs are unique or use index as ID
    Name: user.name,
    email: user.email,
    organization: user.organization,
    organization_id: formatOrganizationId(user.organization_id),
    subscription_tier: user.subscription_tier
  })) : [];

  return (
    <div className="page-container">
      <Typography variant="h4">Admin Panel</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <div className="admin-table-container">
        <div style={{ marginBottom: '16px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            className="standard-button"
            disabled={selectedUsers.length === 0}
            style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' }}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit Users</MenuItem>
            <MenuItem onClick={handleClose}>Align Organization ID</MenuItem>
            <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
          </Menu>
        </div>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionChange}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: 'Black',
                  textAlign: 'center',
                  fontSize: '18px',
                  color: 'white',
                  '& .MuiCheckbox-root': {
                    color: 'white',
                },
              },
              }}
            />
          </div>
        )}
      </div>
      {/* Edit Users Popup */}
      <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
        <DialogTitle>Edit Users</DialogTitle>
        <DialogContent>
          {selectedUsers.length === 1 ? (
            <>
              <TextField
                label="Name"
                value={editedUser.name}
                onChange={(e) => handleEditChange("name", e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Organization"
                value={editedUser.organization}
                onChange={(e) => handleEditChange("organization", e.target.value)}
                fullWidth
                margin="normal"
              />
              <div style={{ display: 'flex', marginBottom: '0px', marginTop: '8px' }}>
                  <div style={{ flex: 1, marginRight: '8px' }}>
                    <Typography>Organization ID:</Typography>
                  </div>
                  <div style={{ flex: 1, marginLeft: '8px' }}>
                  <Typography>Subscription Tier:</Typography>
                  </div>
                </div>
                {organizationIdPairs.map((pair, index) => (
                  <div key={index} style={{ display: 'flex', marginBottom: '0px' }}>
                    <div style={{ flex: 1, marginRight: '8px' }}>
                      <TextField
                        label="ID"
                        value={pair.id}
                        onChange={(e) => handleOrganizationIdChange(index, 'id', e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: '8px' }}>
                      <TextField
                        label="Tier"
                        value={pair.tier}
                        onChange={(e) => handleOrganizationIdChange(index, 'tier', e.target.value)}
                        fullWidth
                        margin="normal"
                        select
                      >
                        <MenuItem value="Basic">Basic</MenuItem>
                        <MenuItem value="Premium User">Premium User</MenuItem>
                      </TextField>
                    </div>
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleAddOrganizationIdRow}>Add Row</Button>
                <Button onClick={handleDeleteOrganizationIdRow} disabled={organizationIdPairs.length === 1}>
                  Delete Row
                </Button>
              </div>
            </>
          ) : (
            <>
              <TextField
                label="Organization"
                value={editedUser.organization}
                onChange={(e) => handleEditChange("organization", e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Organization ID"
                value={editedUser.organization_id}
                onChange={(e) => handleEditChange("organization_id", e.target.value)}
                fullWidth
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditPopup(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      {/* Delete Users Confirmation Dialog */}
      <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
        <DialogTitle>Confirm Remove</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove the selected users?</Typography>
          <FormControlLabel
            control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
            label="Confirm Delete"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showDeleteMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="User Removed"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showEditMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="User Details Updated"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default AdminManagement;

// import React, { useState, useEffect } from "react";
// import { Typography, InputLabel, Select, FormControl, CircularProgress, Snackbar, Alert, IconButton, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel } from "@mui/material";
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import { UsersFetch } from "../components/Backend Routes/fetchAllUsers";
// import { DeleteUsers } from '../components/Backend Routes/DeleteUsers';
// import { UpdateUsers } from '../components/Backend Routes/UpdateUsers';
// import CloseIcon from '@mui/icons-material/Close';

// const columns: GridColDef[] = [
//   { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
//   { field: "firstName", headerName: "First name", flex: 1, minWidth: 150 },
//   { field: "lastName", headerName: "Last name", flex: 1, minWidth: 150 },
//   { field: "email", headerName: "Email", flex: 1.5, minWidth: 250 },
//   { field: "organization", headerName: "Organization", flex: 1, minWidth: 150 },
//   { field: "organization_id", headerName: "Organization ID", flex: 1, minWidth: 150 },
//   { field: "subscription_tier", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
// ];

// const AdminManagement = () => {
//   const { users, loading, error, refetchUsers } = UsersFetch();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [openEditPopup, setOpenEditPopup] = useState(false);
//   const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const [showDeleteMessage, setShowDeleteMessage] = useState(false);
//   const [showEditMessage, setShowEditMessage] = useState(false);
//   const [editedUser, setEditedUser] = useState({
//     first_name: "",
//     last_name: "",
//     organization: "",
//     organization_id: "",
//     subscription_tier: "",
//   });

//   useEffect(() => {
//     if (selectedUsers.length === 1) {
//       const user = users.find((user) => user === selectedUsers[0]);
//       if (user) {
//         setSelectedUser(user);
//         setEditedUser({
//           first_name: user.first_name,
//           last_name: user.last_name,
//           organization: user.organization,
//           organization_id: user.organization_id,
//           subscription_tier: user.subscription_tier,
//         });
//       }
//     } else {
//       setSelectedUser(null);
//       setEditedUser({
//         first_name: "",
//         last_name: "",
//         organization: "",
//         organization_id: "",
//         subscription_tier: "",
//       });
//     }
//   }, [selectedUsers, users]);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleEdit = () => {
//     setOpenEditPopup(true);
//   };

//   const handleDelete = () => {
//     setOpenDeleteConfirmation(true);
//   };

//   const handleSelectionChange = (selection) => {
//     const selectedUserData = selection.map((rowIndex) => users[rowIndex-1]);
//     setSelectedUsers(selectedUserData);
//     console.log('Selected Users: ', selectedUserData);
//   };

//   // const handleEditChange = (field, value) => {
//   //   setEditedUser((prevUser) => ({
//   //     ...prevUser,
//   //     [field]: value,
//   //   }));
//   // };
//   const handleEditChange = (field, value) => {
//     if (field === "organization_id") {
//       setEditedUser((prevUser) => ({
//         ...prevUser,
//         [field]: value,
//       }));
//     } else {
//       setEditedUser((prevUser) => ({
//         ...prevUser,
//         [field]: value,
//       }));
//     }
//   };


//   const handleEditSubmit = async () => {
//     try {
//       await UpdateUsers(selectedUsers, editedUser);
//       // Show success message
//       setShowEditMessage(true);
//       // Update Table
//       refetchUsers();
//       setOpenEditPopup(false);
//       setSelectedUsers([]);
//     } catch (error) {
//       console.error("Error updating users:", error);
//     }
//   };

//   const handleDeleteConfirm = async () => {
//     if (confirmDelete) {
//       try {
//         const databaseIds = selectedUsers.map((user) => user.database_id);
//         console.log('databaseIds: ', databaseIds);
//         await DeleteUsers(selectedUsers);
//         // Show success message
//         setShowDeleteMessage(true);
//         // Close the confirmation dialog and update slates
//         refetchUsers();
//         setOpenDeleteConfirmation(false);
//         setConfirmDelete(false);
//         setSelectedUsers([]);
//       } catch (error) {
//         console.error("Error deleting users:", error);
//       }
//     }
//   };

//   const handleCloseSuccessMessage = () => {
//     setShowDeleteMessage(false);
//     setShowEditMessage(false);
//   };

//   // Ensure that users is an array before mapping
//   const rows = Array.isArray(users) ? users.map((user, index) => ({
//     id: index + 1, // Assuming user IDs are unique or use index as ID
//     firstName: user.first_name,
//     lastName: user.last_name,
//     email: user.email,
//     organization: user.organization,
//     organization_id: user.organization_id,
//     subscription_tier: user.subscription_tier
//   })) : [];

//   return (
//     <div className="page-container">
//       <Typography variant="h4">Admin Panel</Typography>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
//       <div className="admin-table-container">
//         <div style={{ marginBottom: '16px' }}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleClick}
//             className="standard-button"
//             disabled={selectedUsers.length === 0}
//             style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' }}
//           >
//             Actions
//           </Button>
//           <Menu
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//           >
//             <MenuItem onClick={handleEdit}>Edit Users</MenuItem>
//             <MenuItem onClick={handleClose}>Align Organization ID</MenuItem>
//             <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
//           </Menu>
//         </div>
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Alert severity="error">{error}</Alert>
//         ) : (
//           <div style={{ height: 600, width: '100%' }}>
//             <DataGrid
//               rows={rows}
//               columns={columns}
//               initialState={{
//                 pagination: {
//                   paginationModel: { page: 0, pageSize: 5 },
//                 },
//               }}
//               pageSizeOptions={[5, 10]}
//               checkboxSelection
//               onRowSelectionModelChange={handleSelectionChange}
//               sx={{
//                 '& .MuiDataGrid-columnHeader': {
//                   backgroundColor: 'Black',
//                   textAlign: 'center',
//                   fontSize: '18px',
//                   color: 'white',
//                   '& .MuiCheckbox-root': {
//                     color: 'white',
//                 },
//               },
//               }}
//             />
//           </div>
//         )}
//       </div>
//       {/* Edit Users Popup */}
//       <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
//         <DialogTitle>Edit Users</DialogTitle>
//         <DialogContent>
//           {selectedUsers.length === 1 ? (
//             <>
//               <TextField
//                 label="First Name"
//                 value={editedUser.first_name}
//                 onChange={(e) => handleEditChange("first_name", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Last Name"
//                 value={editedUser.last_name}
//                 onChange={(e) => handleEditChange("last_name", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Organization"
//                 value={editedUser.organization}
//                 onChange={(e) => handleEditChange("organization", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Organization ID"
//                 value={editedUser.organization_id}
//                 onChange={(e) => handleEditChange("organization_id", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Subscription Tier"
//                 value={editedUser.subscription_tier}
//                 onChange={(e) => handleEditChange("subscription_tier", e.target.value)}
//                 fullWidth
//                 margin="normal"
//                 variant="outlined"
//                 select
//               >
//                 <MenuItem value="Basic">Basic</MenuItem>
//                 <MenuItem value="Premium User">Admin</MenuItem>
//               </TextField>
//             </>
//           ) : (
//             <>
//               <TextField
//                 label="Organization"
//                 value={editedUser.organization}
//                 onChange={(e) => handleEditChange("organization", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Organization ID"
//                 value={editedUser.organization_id}
//                 onChange={(e) => handleEditChange("organization_id", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenEditPopup(false)}>Cancel</Button>
//           <Button onClick={handleEditSubmit} color="primary">Save</Button>
//         </DialogActions>
//       </Dialog>
//       {/* Delete Users Confirmation Dialog */}
//       <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
//         <DialogTitle>Confirm Remove</DialogTitle>
//         <DialogContent>
//           <Typography>Are you sure you want to remove the selected users?</Typography>
//           <FormControlLabel
//             control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
//             label="Confirm Delete"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
//           <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={showDeleteMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="User Removed"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//       <Snackbar
//         open={showEditMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="Project Details Updated"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//     </div>
//   );
// };

// export default AdminManagement;