// DeleteUser.js
export const DeleteUsers = async (databaseIds, premiumKey) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/team/delete-users`;

  try {
    const response = await fetch(`${url}/${premiumKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( databaseIds ),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};