import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { UsersFetch } from "../components/Backend Routes/fetchUsers";
import { DeleteUsers } from '../components/Backend Routes/DeleteTeamUsers';
import { UpdateUsers } from '../components/Backend Routes/UpdateTeamUsers';
import { AddUser } from '../components/Backend Routes/AddUser';
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
  { field: "name", headerName: "Name", flex: 2, minWidth: 250 },
  { field: "email", headerName: "Email", flex: 2, minWidth: 300 },
  { field: "subscription_tier", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
];

const TeamManagement = () => {
  const { user } = useAuth0();
  const [editEmailError, setEditEmailError] = useState("");
  const [addEmailError, setAddEmailError] = useState("");
  const { users, premiumKey, loading, error, refetchUsers } = UsersFetch(user.email);
  console.log('users: ', users)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [AddPopup, setAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showEditMessage, setShowEditMessage] = useState(false);
  const [editedUser, setEditedUser] = useState({
    name: "",
    // email: "",
    organization_id: [],
  });
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    organization_id: [],
  });
  const navigate = useNavigate();


  console.log('premiumKey: ', premiumKey)

  
  useEffect(() => {
    if (selectedUsers.length === 1) {
      const selectedUser = users.find((u) => u === selectedUsers[0]);
      if (selectedUser) {
    
        if (premiumKey) {
          const filteredOrganizationId = selectedUser.organization_id.filter(
            (orgId) => Object.keys(orgId)[0] === premiumKey
          );

          console.log('filteredOrganizationId: ', filteredOrganizationId[0][premiumKey])
    
          setEditedUser({
            name: selectedUser.name,
            email: selectedUser.email,
            organization_id: filteredOrganizationId[0][premiumKey],
          });
        } else {
          setEditedUser({
            name: selectedUser.name,
            email: selectedUser.email,
            organization_id: [],
          });
        }
      }
    } else {
      setEditedUser({
        name: "",
        email: "",
        organization_id: [],
      });
    }
  }, [selectedUsers, users, user]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = () => {
    setAddPopup(true);
  };

  const handleEdit = () => {
    setOpenEditPopup(true);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleSelectionChange = (selection) => {
    const selectedUserData = selection.map((rowIndex) => users[rowIndex-1]);
    setSelectedUsers(selectedUserData);
    console.log('Selected Users: ', selectedUserData);
  };

  const handleNewUserChange = (field, value) => {
    setNewUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  
    if (field === "email") {
      if (!validateEmail(value)) {
        setAddEmailError("Please enter a valid email address");
      } else {
        setAddEmailError("");
      }
    }
  };

  const handleAddSubmit = async () => {
    try {
      const subscriptionTier = newUser.organization_id[0];
      await AddUser({ ...newUser, organization_id: [subscriptionTier] }, user.email);
      console.log()
      // Show success message
      setShowEditMessage(true);
      // Update Table
      refetchUsers();
      setAddPopup(false);
      setNewUser({
        name: "",
        email: "",
        organization_id: [],
      });
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleEditChange = (field, value) => {
    setEditedUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  
    if (field === "email") {
      if (!validateEmail(value)) {
        setEditEmailError("Please enter a valid email address");
      } else {
        setEditEmailError("");
      }
    }
  };

  const handleEditSubmit = async () => {
    try {
      const subscriptionTier = editedUser.organization_id[0];
      await UpdateUsers({ ...editedUser, organization_id: [subscriptionTier] }, user.email);
      // Show success message
      setShowEditMessage(true);
      // Update Table
      refetchUsers();
      setOpenEditPopup(false);
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error updating users:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    if (confirmDelete) {
      try {
        const databaseIds = selectedUsers.map((user) => user.database_id);
        console.log('databaseIds: ', databaseIds);
        await DeleteUsers(databaseIds, premiumKey);
        // Show success message
        setShowDeleteMessage(true);
        // Close the confirmation dialog and update slates
        refetchUsers();
        setOpenDeleteConfirmation(false);
        setConfirmDelete(false);
        setSelectedUsers([]);
      } catch (error) {
        console.error("Error deleting users:", error);
      }
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowDeleteMessage(false);
    setShowEditMessage(false);
  };

  const validateEmail = (email) => {
    const pattern = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
    return pattern.test(email);
  };

  const returnProfile = () => {
    navigate('/profile');
  };

// Ensure that users is an array before mapping
const rows = Array.isArray(users)
  ? users.map((user, index) => ({
      id: index + 1, // Assuming user IDs are unique or use index as ID
      name: user.name,
      email: user.email,
      subscription_tier: user.organization_id && Array.isArray(user.organization_id) && user.organization_id.length > 0
        ? Object.values(user.organization_id[0])[0]
        : "",
    }))
  : [];

  return (
    <div className="page-container">
      <Tooltip title="Back to Company Profile">
        <IconButton onClick={() => returnProfile()}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Manage Team</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <div className="admin-table-container">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : premiumKey ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                className="standard-button"
                disabled={selectedUsers.length === 0}
                style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary', marginRight: '15px' }}
              >
                Actions
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {selectedUsers.length === 1 && <MenuItem onClick={handleEdit}>Edit Users</MenuItem>}
                <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
              </Menu>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                className="standard-button"
                disabled={selectedUsers.length > 0}
                style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' }}
              >
                Add User
              </Button>
            </div>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                getRowClassName={() => 'table-row'}
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                    '& .MuiCheckbox-root': {
                      color: 'white',
                    },
                  },
                }}
              />
            </div>
          </>
        ) : (
          <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
            Oh Snap! You aren't a premium user so you don't have access to the manage team tab. If you think that's incorrect then reach out to sitesteer_ai@gmail.com.
          </Typography>
        )}
      </div>
      {/* Add User Popup */}
      <Dialog open={AddPopup} onClose={() => setAddPopup(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newUser.name}
            onChange={(e) => handleNewUserChange("name", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => handleNewUserChange("email", e.target.value)}
            fullWidth
            margin="normal"
            error={!!addEmailError}
            helperText={addEmailError}
            required
          />
          <TextField
            label="Subscription Tier"
            value={newUser.organization_id[0]}
            onChange={(e) => handleNewUserChange("organization_id", [e.target.value])}
            fullWidth
            margin="normal"
            variant="outlined"
            select
            required
          >
            <MenuItem value="Basic">Basic</MenuItem>
            <MenuItem value="Premium User">Premium User</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPopup(false)}>Cancel</Button>
          <Button onClick={handleAddSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Users Popup */}
      <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
        <DialogTitle>Edit Users</DialogTitle>
        <DialogContent>
          <>
            <TextField
              label="Name"
              value={editedUser.name}
              onChange={(e) => handleEditChange("name", e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Subscription Tier"
              value={editedUser.organization_id}
              onChange={(e) => handleEditChange("organization_id", [e.target.value])}
              fullWidth
              margin="normal"
              variant="outlined"
              select
              required
            >
              <MenuItem value="Basic">Basic</MenuItem>
              <MenuItem value="Premium User">Premium User</MenuItem>
            </TextField>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditPopup(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      {/* Delete Users Confirmation Dialog */}
      <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
        <DialogTitle>Confirm Remove</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove the selected users?</Typography>
          <FormControlLabel
            control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
            label="Confirm Delete"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showDeleteMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="User Removed"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showEditMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="User Details Updated"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default TeamManagement;

// import React, { useState, useEffect } from "react";
// import {
//     Typography,
//     CircularProgress,
//     Snackbar,
//     Alert,
//     IconButton,
//     Button,
//     Menu,
//     MenuItem,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     TextField,
//     Checkbox,
//     FormControlLabel,
//     Select,
//     InputLabel,
//     FormControl,
//   } from "@mui/material";
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import { UsersFetch } from "../components/Backend Routes/fetchUsers";
// import { DeleteUsers } from '../components/Backend Routes/DeleteUsers';
// import { UpdateUsers } from '../components/Backend Routes/UpdateUsers';
// import { AddUser } from '../components/Backend Routes/AddUser';
// import { useAuth0 } from '@auth0/auth0-react';
// import CloseIcon from '@mui/icons-material/Close';


// const columns: GridColDef[] = [
//     { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
//     { field: "Name", headerName: "Name", flex: 2, minWidth: 250 },
//     { field: "email", headerName: "Email", flex: 2, minWidth: 300 },
//     // { field: "subscription_tier", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
//     { field: "organization_id", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
//   ];

// const TeamManagement = () => {
//   const { user } = useAuth0();
//   console.log('user: ', user.email)
//   const { users, loading, error, refetchUsers } = UsersFetch(user.email);
//   const [anchorEl, setAnchorEl] = useState(null);  
//   const open = Boolean(anchorEl);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [AddPopup, setAddPopup] = useState(false);
//   const [openEditPopup, setOpenEditPopup] = useState(false);
//   const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const [showDeleteMessage, setShowDeleteMessage] = useState(false);
//   const [showEditMessage, setShowEditMessage] = useState(false);
//   const [editedUser, setEditedUser] = useState({
//     name: "",
//     email: "",
//     organization_id: "",
//     // subscription_tier: "",
//   });
//   const [newUser, setNewUser] = useState({
//     name: "",
//     email: "",
//     organization_id: "",
//     // subscription_tier: "",
//   });

//   useEffect(() => {
//     if (selectedUsers.length === 1) {
//       const user = users.find((user) => user === selectedUsers[0]);
//       if (user) {
//         setEditedUser({
//           name: user.name,
//           email: user.organization,
//           organization_id: user.subscription_tier,
//           // subscription_tier: user.subscription_tier,
//         });
//       }
//     } else {
//       setEditedUser({
//         name: "",
//         email: "",
//         organization_id: "",
//         // subscription_tier: "",
//       });
//     }
//   }, [selectedUsers, users]);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleAdd = () => {
//     setAddPopup(true);
//   };

//   const handleEdit = () => {
//     setOpenEditPopup(true);
//   };

//   const handleDelete = () => {
//     setOpenDeleteConfirmation(true);
//   };

//   const handleSelectionChange = (selection) => {
//     const selectedUserData = selection.map((rowIndex) => users[rowIndex-1]);
//     setSelectedUsers(selectedUserData);
//     console.log('Selected Users: ', selectedUserData);
//   };

//   const handleNewUserChange = (field, value) => {
//     setNewUser((prevUser) => ({
//       ...prevUser,
//       [field]: value,
//     }));
//   };

//   const handleAddSubmit = async () => {
//     try {
//       await AddUser(newUser);
//       // Show success message
//       setShowEditMessage(true);
//       // Update Table
//       refetchUsers();
//       setAddPopup(false);
//       setNewUser({
//         name: "",
//         email: "",
//         organization_id: "",
//         // subscription_tier: "",
//       });
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };


//   const handleEditChange = (field, value) => {
//     setEditedUser((prevUser) => ({
//       ...prevUser,
//       [field]: value,
//     }));
//   };

//   const handleEditSubmit = async () => {
//     try {
//       await UpdateUsers(selectedUsers, editedUser);
//       // Show success message
//       setShowEditMessage(true);
//       // Update Table
//       refetchUsers();
//       setOpenEditPopup(false);
//       setSelectedUsers([]);
//     } catch (error) {
//       console.error("Error updating users:", error);
//     }
//   };

//   const handleDeleteConfirm = async () => {
//     if (confirmDelete) {
//       try {
//         const databaseIds = selectedUsers.map((user) => user.database_id);
//         console.log('databaseIds: ', databaseIds);
//         await DeleteUsers(selectedUsers);
//         // Show success message
//         setShowDeleteMessage(true);
//         // Close the confirmation dialog and update slates
//         refetchUsers();
//         setOpenDeleteConfirmation(false);
//         setConfirmDelete(false);
//         setSelectedUsers([]);
//       } catch (error) {
//         console.error("Error deleting users:", error);
//       }
//     }
//   };

//   const handleCloseSuccessMessage = () => {
//     setShowDeleteMessage(false);
//     setShowEditMessage(false);
//   };

//   // Ensure that users is an array before mapping
//   const rows = Array.isArray(users) ? users.map((user, index) => ({
//     id: index + 1, // Assuming user IDs are unique or use index as ID
//     Name: user.name,
//     email: user.email,
//     organization_id: user.organization_id
//   })) : [];

//   return (
//     <div className="page-container">
//       <Typography variant="h4">Manage Team</Typography>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
//       <div className="admin-table-container">
//         <div style={{ marginBottom: '16px' }}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleClick}
//             className="standard-button"
//             disabled={selectedUsers.length === 0}
//             style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary', marginRight: '15px'}}
//           >
//             Actions
//           </Button>
//             <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
//             {selectedUsers.length === 1 && <MenuItem onClick={handleEdit}>Edit Users</MenuItem>}
//             <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
//           </Menu>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleAdd}
//             className="standard-button"
//             disabled={selectedUsers.length > 0}
//             style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' }}
//           >
//             Add User
//           </Button>
//         </div>
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Alert severity="error">{error}</Alert>
//         ) : (
//           <div style={{ height: 500, width: '100%' }}>
//             <DataGrid
//               rows={rows}
//               columns={columns}
//               initialState={{
//                 pagination: {
//                   paginationModel: { page: 0, pageSize: 5 },
//                 },
//               }}
//               pageSizeOptions={[5, 10]}
//               checkboxSelection
//               onRowSelectionModelChange={handleSelectionChange}
//               getRowClassName={() => 'table-row'}
//               sx={{
//                 '& .MuiDataGrid-columnHeader': {
//                     backgroundColor: 'Black',
//                     textAlign: 'center',
//                     fontSize: '18px',
//                     color: 'white',
//                     '& .MuiCheckbox-root': {
//                       color: 'white',
//                   },
//                 },
//               }}
//             />
//           </div>
//         )}
//       </div>
//     {/* Add User Popup */}
//     <Dialog open={AddPopup} onClose={() => setAddPopup(false)}>
//         <DialogTitle>Add User</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Name"
//             value={newUser.name}
//             onChange={(e) => handleNewUserChange("name", e.target.value)}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Email"
//             value={newUser.email}
//             onChange={(e) => handleNewUserChange("email", e.target.value)}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Subscription Tier"
//             value={editedUser.subscription_tier}
//             onChange={(e) => handleEditChange("organization_id", e.target.value)}
//             fullWidth
//             margin="normal"
//             variant="outlined"
//             select
//           >
//             <MenuItem value="Basic">Basic</MenuItem>
//             <MenuItem value="Premium User">Premium User</MenuItem>
//           </TextField>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setAddPopup(false)}>Cancel</Button>
//           <Button onClick={handleAddSubmit} color="primary">
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>
//       {/* Edit Users Popup */}
//       <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
//         <DialogTitle>Edit Users</DialogTitle>
//         <DialogContent>
//             <>
//               <TextField
//                 label="Name"
//                 value={editedUser.name}
//                 onChange={(e) => handleEditChange("name", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Email"
//                 value={editedUser.email}
//                 onChange={(e) => handleEditChange("email", e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Subscription Tier"
//                 value={editedUser.subscription_tier}
//                 onChange={(e) => handleEditChange("subscription_tier", e.target.value)}
//                 fullWidth
//                 margin="normal"
//                 variant="outlined"
//                 select
//               >
//                 <MenuItem value="Basic">Basic</MenuItem>
//                 <MenuItem value="Premium User">Premium User</MenuItem>
//               </TextField>
//             </>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenEditPopup(false)}>Cancel</Button>
//           <Button onClick={handleEditSubmit} color="primary">Save</Button>
//         </DialogActions>
//       </Dialog>
//       {/* Delete Users Confirmation Dialog */}
//       <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
//         <DialogTitle>Confirm Remove</DialogTitle>
//         <DialogContent>
//           <Typography>Are you sure you want to remove the selected users?</Typography>
//           <FormControlLabel
//             control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
//             label="Confirm Delete"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
//           <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={showDeleteMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="User Removed"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//       <Snackbar
//         open={showEditMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="Project Details Updated"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//     </div>
//   );
// };

// export default TeamManagement;