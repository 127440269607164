// ProspectFetch.jsx
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCrmCompanies = async (premiumKey) => {
  try {
    const response = await fetch(`${API_BASE_URL}/crm/customer-list/?owner=${premiumKey}`);
    if (!response.ok) throw new Error('Failed to fetch CRM data');
    const data = await response.json();
    console.log("Raw CRM data:", data);

    if (!data || !Array.isArray(data.customers)) {
      console.error("Unexpected data structure:", data);
      throw new Error('Unexpected data structure from CRM API');
    }

    return data.customers;  // This is now an array of { companyId, name } objects
  } catch (err) {
    console.error("Error fetching CRM companies:", err);
    throw err;
  }
};

  export const fetchProspectData = async (premiumKey) => {
    try {
      const response = await fetch(`${API_BASE_URL}/prospect/merged-prospect-data/?owner=${premiumKey}`);
      if (!response.ok) throw new Error('Failed to fetch prospect data');
      const data = await response.json();
      console.log("Raw prospect data:", data);  // Log the raw data
  
      if (!data || !data.items) {
        console.error("Unexpected data structure:", data);
        throw new Error('Unexpected data structure from Prospect API');
      }
  
      return data.items.map((item, index) => ({ ...item, id: index }));
    } catch (err) {
      console.error("Error fetching prospect data:", err);
      throw err;
    }
  };

export const updateProspectData = async (premiumKey, newData) => {
  try {
    const cleanedData = newData.map(({ id, ...item }) => ({
      company: item.company,
      project: item.project,
      address: item.address,
      status: item.status,
      mostRecentActivity: item.mostRecentActivity,
    }));
    
    const requestBody = {
      owner_org: premiumKey,
      items: cleanedData
    };

    console.log("Sending data to backend:", requestBody);

    const response = await fetch(`${API_BASE_URL}/prospect/prospect-details/?owner=${premiumKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    
    if (!response.ok) {
      const responseData = await response.json();
      let errorMessage = 'Failed to update prospect data';
      if (responseData.detail) {
        if (Array.isArray(responseData.detail)) {
          errorMessage += ': ' + responseData.detail.map(err => JSON.stringify(err)).join(', ');
        } else {
          errorMessage += ': ' + responseData.detail;
        }
      }
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating prospect data:", error);
    throw error;
  }
};

/**
   * Handle archiving a prospect.
   * This function is called when the Archive icon is clicked.
   * It performs the archive operation and updates the local state accordingly.
   * @param {string} id - The unique identifier of the prospect to archive.
   */
export const archiveProspect = async (premiumKey, id) => {
  console.log(`Prospects - Archiving prospect with id: ${id}`);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/prospect/archive/?owner=${premiumKey}&projectId=${id}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to archive prospect");
    }

    console.log(`Prospects - Prospect with id ${id} archived.`);
    return true; // Indicate success
  } catch (error) {
    console.error("Prospects - Error archiving prospect:", error);
    throw error;
  }
};