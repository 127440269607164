// Prospects.jsx
import React, { useState, useContext, useEffect, useMemo, useRef, useCallback} from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip
} from "@mui/material";
import { UserContext } from "../components/UserContext"; // Context for user information
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenuFilterItem,
  GridColumnMenuSortItem,
  getGridStringOperators,
} from "@mui/x-data-grid"; // MUI DataGrid components
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Hooks for navigation and accessing location
import { useAuth0 } from "@auth0/auth0-react"; // Auth0 for authentication
import CloseIcon from "@mui/icons-material/Close"; // Icon for closing elements
import ArchiveIcon from "@mui/icons-material/Archive"; // Icon for archive action
import EditIcon from "@mui/icons-material/Edit"; // Icon for edit action
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete action
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';  // Icon for pushing action
import {
  fetchProspectData,
  fetchCrmCompanies,
  archiveProspect
} from "../components/Backend Routes/ProspectFetch"; // Backend API functions

const Prospects = () => {
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location:', location.search);

  // Ref flag to indicate programmatic filter updates
  const initialFilterSet = useRef(false);

  // Extract user information from Auth0
  const { user } = useAuth0();

  // Extract premium user information from context
  const { isPremiumUser, premiumKey } = useContext(UserContext);

  // State variables for handling automatic opening of Add Popup
  const [shouldOpenAddPopup, setShouldOpenAddPopup] = useState(false);

  // State variables for handling confirmation dialogs, deletions and archiving
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmationArchiveDialog, setOpenConfirmationArchiveDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToArchiveId, setItemToArchiveId] = useState(null); // State for archiving prospects

  // State for storing prospect and CRM companies data
  const [prospectData, setProspectData] = useState([]);
  const [crmCompanies, setCrmCompanies] = useState([]);

  // State variables for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialCompanyId, setInitialCompanyId] = useState(null);

  // State variables for add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [editedItem, setEditedItem] = useState({
    projectName: "",
    site_address: "",
    companyId: "",
    projectId: "", // Assuming projectId is a unique identifier
    status: "",
  });

  // State variables for displaying messages to the user
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");

  // State for managing the filter model in DataGrid
  const [filterModel, setFilterModel] = useState({ items: [] });

  // Snackbar state variable set and include severity
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" // Can be "success", "error", "warning", "info"
  });

   /**
   * Create a mapping from companyId to companyName for quick lookup.
   * Utilizes React's useMemo for performance optimization.
   */
   const companyLookup = useMemo(() => {
    return prospectData.reduce((acc, prospect) => {
      if (prospect.companyId && prospect.companyName) {
        acc[prospect.companyId] = prospect.companyName;
      }
      return acc;
    }, {});
  }, [prospectData]);

  
  /**
   * Fetching prospect and CRM companies data from the backend API.
   * Ensures that each prospect has a unique 'id' required by DataGrid.
   */
  const loadData = useCallback(async () => {
    try {
      const prospects = await fetchProspectData(premiumKey);
      const companies = await fetchCrmCompanies(premiumKey);
      
      // Initialize a Set to track unique projectIds
      const idSet = new Set();
      
      // Map each prospect to include a unique 'id' based on 'projectId'
      const mappedProspects = prospects.map((prospect) => {
        if (!prospect.projectId) {
          console.warn('Prospect missing projectId:', prospect);
          // Assign a temporary unique id if 'projectId' is missing
          return { ...prospect, id: `missing-id-${Math.random()}` };
        }
        if (idSet.has(prospect.projectId)) {
          console.warn('Duplicate projectId found:', prospect.projectId);
          // Assign a unique id by appending a random number if 'projectId' is duplicated
          return { ...prospect, id: `duplicate-id-${prospect.projectId}-${Math.random()}` };
        }
        idSet.add(prospect.projectId);
        return { ...prospect, id: prospect.projectId };
      });
      
      setProspectData(mappedProspects);
      setCrmCompanies(companies);
      setLoading(false);
      setDataLoaded(true);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [premiumKey]);

  useEffect(() => {
    loadData();
  }, [loadData]);

    /**
   * Extract 'companyId' and 'companyName' from URL query parameters and apply it as a filter.
   * Prevents infinite loops by checking if the filter is already set.
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const companyName = params.get("companyName");
    const companyId = params.get('companyId');

    if (action === 'add' && companyId) {
      setInitialCompanyId(companyId);
    }

    else if (companyName) {
      console.log('Prospects - Applying initial filter for companyId:', companyId);
      console.log('Prospects - Corresponding company name:', companyLookup[companyId]);
      setFilterModel({
        items: [
          {
            field: "companyName",
            operatorValue: "equals",
            value: companyName,
          },
        ],
      });
      initialFilterSet.current = true;
    }
  }, [location.search]);

    /**
   * If data is loaded and an additional initialCompanyId is set from
   * the URL search paramters it then sets companyId of EditedItem for the popup to prepopulate
   */
  useEffect(() => {
    if (dataLoaded && initialCompanyId) {
      const company = crmCompanies.find(c => c.companyId === initialCompanyId);
      if (company) {
        setEditedItem({
          projectName: "",
          site_address: "",
          companyId: company.companyId,
          projectId: "",
          status: "",
        });
        setOpenAddEditPopup(true);
      }
      setInitialCompanyId(null); // Reset to prevent reopening
    }
  }, [dataLoaded, initialCompanyId, crmCompanies]);

  // Opens AddEdit
  useEffect(() => {
    if (shouldOpenAddPopup) {
      handleAddEdit();
      setShouldOpenAddPopup(false);
    }
  }, [shouldOpenAddPopup]);


  /**
   * Handle opening the add/edit popup.
   * If an 'id' is provided, populate the form with existing data for editing.
   * Otherwise, reset the form for adding a new prospect.
   * @param {string|null} id - The unique identifier of the prospect to edit, or null for adding.
   */
  const handleAddEdit = (id = null) => {
    if (id !== null) {
      const itemToEdit = prospectData.find((item) => item.id === id);
      console.log("Prospects - Editing item:", itemToEdit);
      setEditedItem({ ...itemToEdit }); // Populate form with existing data
    } else {
      // Reset form fields for adding a new prospect
      setEditedItem({
        projectName: "",
        address: "",
        companyId: "",
        projectId: "",
        status: "",
      });
      console.log("Prospects - Adding new prospect.");
    }
    setOpenAddEditPopup(true); // Open the add/edit dialog
  };

  /**
   * Handle initiating the deletion of a prospect.
   * Opens a confirmation dialog.
   * @param {string} id - The unique identifier of the prospect to delete.
   */
  const handleDelete = (id) => {
    console.log(`Prospects - Initiating deletion for id: ${id}`);
    setItemToDeleteId(id); // Set the id of the item to delete
    setConfirmDelete(false); // Reset the confirmation checkbox
    setOpenConfirmationDialog(true); // Open the confirmation dialog
  };

 /**
   * Handle initiating the archiving of a prospect.
   * Opens an archive confirmation dialog or performs archiving logic.
   * @param {string} id - The unique identifier of the prospect to archive.
   */
 const handleArchive = async (id) => {
  console.log(`Prospects - Initiating archive for id: ${id}`);
  setItemToArchiveId(id);
  setConfirmArchive(false); // Reset the confirmation checkbox
  setOpenConfirmationArchiveDialog(true); // Open the confirmation dialog
};

  /**
   * Confirm and execute the deletion of a prospect.
   * Sends a DELETE request to the backend API and updates local state upon success.
   */
  const handleConfirmDelete = async () => {
    if (confirmDelete && itemToDeleteId !== null) {
      try {
        const updatedData = prospectData.filter(item => item.id !== itemToDeleteId);
        // Send filtered data without the deleted row to the backend
        await updateProspects(updatedData);
        // Update local state
        setProspectData(updatedData);
        setSnackbar({
          open: true,
          message: "Prospect successfully deleted from Prospects",
          severity: "success"
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to delete Prospect entry",
          severity: "error"
        });
      } finally {
        setOpenConfirmationDialog(false);
        setItemToDeleteId(null);
      }
    }
  };


  /**
   * Confirm and execute the archive of a prospect.
   * Sends an Archive request to the backend API and updates local state upon success.
   */
  const handleConfirmArchive = async () => {
    if (confirmArchive && itemToArchiveId !== null) {
      try {
        console.log('Archiving prospect with ID:', itemToArchiveId);
        
        // Send archive request to the backend
        await archiveProspect(premiumKey, itemToArchiveId);

        // Close the confirmation dialog
        setOpenConfirmationArchiveDialog(false);

        // Update local state to reflect the change
        setProspectData((prevData) =>
          prevData.map((item) =>
            item.id === itemToArchiveId ? { ...item, status: "Archived" } : item
          )
        );

        // Show success message
        setMessageText("Prospect archived successfully");
        setShowMessage(true);

        // Optionally, refresh data from the server
        // await loadData();  // Uncomment if you want to refresh all data
      } catch (error) {
        console.error("Error archiving prospect:", error);
        setMessageText(`Failed to archive prospect: ${error.message}`);
        setShowMessage(true);
      } finally {
        // Reset the archive-related state
        setItemToArchiveId(null);
        setConfirmArchive(false);
      }
    }
  };

   /**
   * Handle initiating the creation of a quote.
   */
 const handleCreateQuote = (id) => {
  console.log(`Prospects - Initiating prospect creation for id: ${id}`);
  const prospect = prospectData.find(item => item.id === id);
  if (prospect) {
    navigate(`/quotes?action=add&companyId=${prospect.companyId}&projectId=${encodeURIComponent(prospect.projectId)}`);
  }
};

  /**
   * Handle changes in the add/edit form fields.
   * Updates the 'editedItem' state accordingly.
   * @param {string} field - The form field being updated.
   * @param {string} value - The new value of the form field.
   */
  const handleChange = (field, value) => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
    console.log(`Prospects - Updated field '${field}' to '${value}'.`);
  };

  /**
   * Handle the submission of the add/edit form.
   * Sends a POST or PUT request to the backend API based on whether an 'id' exists.
   * Refreshes the prospect data upon successful submission.
   */
  const handleSubmit = async () => {
    try {
      // Find the company name based on the selected companyId
      const selectedCompany = crmCompanies.find(company => company.companyId === editedItem.companyId);

      let newProspect = {
        ...editedItem,
        companyName: selectedCompany ? selectedCompany.name : 'Unknown Company', // Include company name
        id: editedItem.id || `new-${Date.now()}`, // Ensure a unique id for new prospects
      };

      let updatedData;
      if (editedItem.id !== undefined) {
        // Updating existing prospect
        updatedData = prospectData.map(item => 
          item.id === editedItem.id ? newProspect : item
        );
        await updateProspects(updatedData);
        setSnackbar({
          open: true,
          message: "Prospect successfully edited",
          severity: "success"
        });
      } else { // Adding new prospect
        updatedData = [...prospectData, newProspect];
        await updateProspects(updatedData);
        setSnackbar({
          open: true,
          message: "New prospect successfully added to Prospects",
          severity: "success"
        });
      }

      // Update local state
      setProspectData(updatedData);

      setOpenAddEditPopup(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update prospect entry",
        severity: "error"
      });
    }
  };

  // Update the updateProspects function
  const updateProspects = async (newData) => {
    try {
      // Remove the 'id' field and transform data for backend
      const cleanedData = newData.map(({ id, ...item }) => ({
        projectName: item.projectName,
        site_address: item.site_address,
        companyId: item.companyId,
        projectId: item.projectId || "",
        status: item.status || "",
        // Note: We don't send companyName to the backend as it should be derived from companyId
      }));
      
      const requestBody = {
        owner_org: premiumKey,
        items: cleanedData
      };

      console.log("Sending data to backend:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/prospect/prospect-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to update prospect data: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("Full response from server:", responseData);

      // Note: We don't set prospectData here as we'll do a full refresh in handleSubmit

    } catch (error) {
      console.error("Error updating Prospect data:", error);
      throw error; // Propagate error to be handled in handleSubmit
    }
  };

  /**
   * Handle closing the Snackbar message.
   */
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar({ ...snackbar, open: false });
    };


  /**
   * Custom Column Menu for DataGrid.
   * Restricts the column menu to only sort and filter options.
   * @param {object} props - Properties passed to the custom column menu.
   */
  const CustomColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
      <div>
        <GridColumnMenuSortItem onClick={hideMenu} column={currentColumn} />
        <GridColumnMenuFilterItem onClick={hideMenu} column={currentColumn} />
      </div>
    );
  };

  /**
   * Define the columns for the DataGrid, including field names, header names, flex properties, and filter operators.
   * The 'actions' column includes edit, delete, and archive actions for each row.
   */
  const columns = useMemo(
    () => [
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "projectName",
        headerName: "Project Name",
        flex: 1.5,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "site_address",
        headerName: "Project Address",
        flex: 2,
        minWidth: 300,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "most_recent",
        headerName: "Most Recent Activity",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "actions", // Special column for action buttons
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <Tooltip title="Edit Prospect">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleAddEdit(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete Prospect">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Archive Prospect">
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label="Archive"
              onClick={() => handleArchive(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Create Quote">
          <GridActionsCellItem
            icon={<ArrowCircleRightIcon />}
            label="Archive"
            onClick={() => handleCreateQuote(id)}
            color="inherit"
          />
          </Tooltip>,
          ];
        },
      },
    ],
    [companyLookup, handleAddEdit, handleDelete, handleArchive, handleCreateQuote] // Dependencies for useMemo
  );

  /**
   * Create a mapping from field names to header names for display in filter bubbles.
   * Utilizes React's useMemo for performance optimization.
   */
  const fieldToHeaderName = useMemo(() => {
    const mapping = {};
    columns.forEach((column) => {
      mapping[column.field] = column.headerName;
    });
    return mapping;
  }, [columns]);

  /**
   * Render the applied filters as visual filter bubbles above the DataGrid.
   * Each bubble displays the header name and filter value, and includes a close icon to remove the filter.
   */
  const renderAppliedFilters = () => (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "10px" }}>
      {filterModel.items.map((filter, index) =>
        filter.value ? (
          <div
            key={index}
            style={{
              backgroundColor: "#FFD700",
              borderRadius: "20px",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              {`${fieldToHeaderName[filter.field] || filter.field} - ${
                filter.field === "companyId" && companyLookup ? companyLookup[filter.value] || filter.value : filter.value
              }`}
            </Typography>
            <IconButton size="small" onClick={() => removeFilter(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ) : null
      )}
    </div>
  );

  /**
   * Remove a specific filter based on its index in the filter model.
   * Updates the 'filterModel' state to exclude the removed filter.
   * @param {number} filterIndex - The index of the filter to remove.
   */
  const removeFilter = (filterIndex) => {
    console.log(`Prospects - Removing filter at index: ${filterIndex}`);
    const newItems = [...filterModel.items];
    newItems.splice(filterIndex, 1); // Remove the filter at the specified index
    setFilterModel({ ...filterModel, items: newItems }); // Update the filter model state
  };

  /**
   * Handle changes to the filter model from the DataGrid.
   * Updates the local 'filterModel' state to synchronize with filter bubbles.
   * @param {object} model - The new filter model.
   */
  const handleFilterModelChange = (newModel) => {
    console.log("Prospects - Filter Model Changed:", newModel);
    // Only update if the new model is different from the current one
    setFilterModel((prevModel) => {
      if (JSON.stringify(prevModel) !== JSON.stringify(newModel)) {
        return newModel;
      }
      return prevModel;
    });
  };

      /**
   * Handle double-click event on a DataGrid row.
   * Navigates to the Prospects page and passes the selected company's ID.
   * @param {object} params - Parameters containing row information.
   */
  const handleRowDoubleClick = (params) => {
    console.log('params double click', params)
    const companyName = params.row.companyName;
    // Navigate to Prospects page with companyName as a query parameter
    navigate(`/quotes?companyName=${companyName}`);
  };

  /**
   * Render the Prospect Details page, including the DataGrid and dialogs for add/edit/delete operations.
   */
  return (
    <div className="page-container">
      {/* Page Title */}
      <Typography variant="h4">Prospect Details</Typography>
      <hr
        style={{
          borderTop: "2px solid black",
          borderBottom: "2px solid black",
        }}
      />

      {/* Render Applied Filters as Bubbles */}
      {renderAppliedFilters()}

      <div className="crm-details-container">
        {loading ? (
          // Show a loading spinner while data is being fetched
          <CircularProgress />
        ) : error ? (
          // Display an error alert if there's an error fetching data
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Button to Add a New Prospect */}
            <div style={{ marginBottom: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: "15px" }}
              >
                Add Prospect
              </Button>
            </div>

            {/* DataGrid Displaying Prospect Data */}
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={prospectData} // Rows data
                columns={columns} // Columns configuration
                pageSize={10} // Number of rows per page
                rowsPerPageOptions={[5, 10, 20]} // Options for rows per page
                disableSelectionOnClick // Disable row selection on click
                onRowDoubleClick={handleRowDoubleClick} // Handle row double-click
                filterModel={filterModel} // Controlled filter model
                onFilterModelChange={handleFilterModelChange} // Handle filter model changes
                components={{
                  // Use custom column menu to limit options
                  ColumnMenu: CustomColumnMenu,
                }}
                sx={{
                  // Styling for the DataGrid
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#DDD6CC",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "black",
                  },
                  "& .MuiDataGrid-menuIcon": {
                    color: "black",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    color: "black",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Dialog for Adding/Editing a Prospect */}
      <Dialog
        open={openAddEditPopup}
        onClose={() => setOpenAddEditPopup(false)}
      >
        <DialogTitle>
          {editedItem && editedItem.id !== undefined
            ? "Edit Prospect"
            : "Add Prospect"}
        </DialogTitle>
        <DialogContent>
          {/* Company Name Selection */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Company Name</InputLabel>
            <Select
              value={editedItem.companyId || ""}
              onChange={(e) => handleChange("companyId", e.target.value)}
              label="Company Name"
              required
            >
              {Array.isArray(crmCompanies)
                ? crmCompanies.map((company) => (
                    <MenuItem
                      key={company.companyId}
                      value={company.companyId} // Ensure it matches the data type
                    >
                      {company.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>

          {/* Project Name Input */}
          <TextField
            label="Project Name"
            value={editedItem?.projectName || ""}
            onChange={(e) => handleChange("projectName", e.target.value)}
            fullWidth
            margin="normal"
            required
          />

          {/* Project Address Input */}
          <TextField
            label="Project Address"
            value={editedItem?.site_address || ""}
            onChange={(e) => handleChange("site_address", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          {/* Cancel Button */}
          <Button onClick={() => setOpenAddEditPopup(false)}>Cancel</Button>
          {/* Save Button */}
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Confirming Deletion of a Prospect */}
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Prospect entry?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
              />
            }
            label="Yes, I want to delete this prospect"
          />
        </DialogContent>
        <DialogActions>
          {/* Cancel Button */}
          <Button onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </Button>
          {/* Delete Button */}
          <Button
            onClick={handleConfirmDelete}
            color="error"
            disabled={!confirmDelete} // Disable until confirmation checkbox is checked
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

     {/* Dialog for Confirming Acrhiving of a Prospect */}
     <Dialog
        open={openConfirmationArchiveDialog}
        onClose={() => setOpenConfirmationArchiveDialog(false)}
      >
        <DialogTitle>Confirm Archiving</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to archive this Prospect entry?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmArchive}
                onChange={(e) => setConfirmArchive(e.target.checked)}
              />
            }
            label="Yes, I want to archive this prospect"
          />
        </DialogContent>
        <DialogActions>
          {/* Cancel Button */}
          <Button onClick={() => setOpenConfirmationArchiveDialog(false)}>
            Cancel
          </Button>
          {/* Archive Button */}
          <Button
            onClick={handleConfirmArchive}
            color="error"
            disabled={!confirmArchive} // Disable until confirmation checkbox is checked
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Displaying Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Prospects;
