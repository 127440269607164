// import * as React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';
// import { useAuth0 } from "@auth0/auth0-react";
// import logo from "../images/logo_nobacgkrund_withname.png";

// const sections = ['Key Features', 'FAQ', 'Pricing'];
// const sectionLinks = ['#key-features', '#faq', '#pricing'];

// const pages = ['CRM', 'Prospects', 'Quotes', 'Projects', 'Invoice', 'Audit Dashboard'];
// const links = ['/crm', '/prospects', '/quotes', '/live-projects', '/invoice', '/dashboard'];
// const settings = ['Company Profile', 'Logout'];

// function ResponsiveAppBar() {
//   const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const isRootPage = location.pathname === '/';

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   const handleLogout = () => {
//     logout({ returnTo: window.location.origin });
//   };

//   const handleProfileClick = () => {
//     navigate('/profile');
//     handleCloseUserMenu();
//   };

//   const registerInterest = () => {
//     navigate('/early-signup');
//   };

//   const Back2Home = () => {
//     navigate('/');
//   };

//   const getInitials = (name) => {
//     const names = name.split(' ');
//     const initials = names.map((name) => name.charAt(0).toUpperCase());
//     return initials.join('');
//   };

//   return (
//     <AppBar position="fixed" sx={{ backgroundColor: '#F4C50B', height: '60px'}}>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
//           <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', height: '100%' }}>
//             <img onClick={Back2Home} src={logo} alt="SiteSteer Logo!" style={{ width: '70px', height: '70px', marginRight: '8px'}} />
//             <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start'}}>
//               {!isAuthenticated && isRootPage && sections.map((section, index) => (
//                 <Button
//                   key={section}
//                   component={Link}
//                   to={sectionLinks[index]}
//                   onClick={handleCloseNavMenu}
//                   sx={{ color: 'black', fontStyle: 'bold'}}
//                   className="nav-link-section"
//                 >
//                   {section}
//                 </Button>
//               ))}
//               {isAuthenticated && pages.map((page, index) => (
//                 <Button
//                   key={page}
//                   component={Link}
//                   to={links[index]}
//                   onClick={handleCloseNavMenu}
//                   sx={{ my: 2, color: 'white', display: 'block' }}
//                   className="nav-link-bubble"
//                 >
//                   {page}
//                 </Button>
//               ))}
//             </Box>
//           </Box>
//           <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//             >
//               {!isAuthenticated
//                 ? sections.map((section, index) => (
//                     <MenuItem key={section} component={Link} to={sectionLinks[index]} onClick={handleCloseNavMenu}>
//                       <Typography textAlign="center">{section}</Typography>
//                     </MenuItem>
//                   ))
//                 : pages.map((page, index) => (
//                     <MenuItem key={page} component={Link} to={links[index]} onClick={handleCloseNavMenu}>
//                       <Typography textAlign="center">{page}</Typography>
//                     </MenuItem>
//                   ))}
//             </Menu>
//           </Box>
//           <Box sx={{ flexGrow: 0, display: 'flex' }}>
//             {!isAuthenticated ? (
//               <>
//                 <Button
//                   onClick={() => loginWithRedirect()}
//                   sx={{ color: 'black', fontStyle: 'bold'}}
//                   className="nav-link-section"
//                 >
//                   Login
//                 </Button>
//                 {isRootPage && (
//                 <Button
//                   onClick={() => registerInterest()}
//                   sx={{ my: 2, color: "white", display: "block" }}
//                   className="nav-link-bubble"
//                 >
//                   Try for Free
//                 </Button>
//                 )}
//               </>
//             ) : (
//               <Tooltip title="Open settings">
//                 <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//                   <Avatar className="Avatar">{getInitials(user.name)}</Avatar>
//                 </IconButton>
//               </Tooltip>
//             )}
//             <Menu
//               sx={{ mt: '45px' }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem 
//                   key={setting} 
//                   onClick={setting === 'Company Profile' ? handleProfileClick : setting === 'Logout' ? handleLogout : handleCloseUserMenu}
//                 >
//                   <Typography textAlign="center">{setting}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }

// export default ResponsiveAppBar;

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../images/logo_nobacgkrund_withname.png";

const sections = ['Key Features', 'FAQ', 'Pricing'];
const sectionLinks = ['#key-features', '#faq', '#pricing'];

const pages = ['CRM', 'Prospects', 'Quotes', 'Projects', 'Invoice', 'Audit Dashboard'];
const links = ['/crm', '/prospects', '/quotes', '/live-projects', '/invoice', '/dashboard'];
const settings = ['Company Profile', 'Logout'];

function ResponsiveAppBar() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isRootPage = location.pathname === '/';

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleProfileClick = () => {
    navigate('/profile');
    handleCloseUserMenu();
  };

  const registerInterest = () => {
    navigate('/early-signup');
  };

  const Back2Home = () => {
    navigate('/');
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.join('');
  };


  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#F4C50B', height: '60px'}}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', position: 'relative' }}>
          <Box sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
            <img onClick={Back2Home} src={logo} alt="SiteSteer Logo!" style={{ width: '90px', height: '90px', cursor: 'pointer' }} />
          </Box>
          
          {isAuthenticated ? (
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1, alignItems: 'center', height: '100%', pl: '80px', pr: '80px' }}>
              {pages.map((page, index) => (
                <React.Fragment key={page}>
                  <Button
                    onClick={() => navigate(links[index])}
                    sx={{
                      color: 'white',
                      backgroundColor: location.pathname === links[index] ? '#8784d2' : 'black',
                      borderRadius: '20px',
                      padding: '6px 16px',
                      margin: '0 4px',
                      minWidth: '150px',
                      height: '36px',
                      '&:hover': {
                        backgroundColor: location.pathname === links[index] ? '#E6E6FA' : '#333',
                      },
                    }}
                  >
                    {page}
                  </Button>
                  {index < pages.length - 1 && (
                    <ArrowForwardIosIcon sx={{ color: 'white', margin: '0 4px', fontSize: '1.5rem' }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start' }}>
              {isRootPage && sections.map((section, index) => (
                <Button
                  key={section}
                  component={Link}
                  to={sectionLinks[index]}
                  onClick={handleCloseNavMenu}
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  className="nav-link-section"
                >
                  {section}
                </Button>
              ))}
            </Box>
          )}
          
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {isAuthenticated
                ? pages.map((page, index) => (
                    <MenuItem key={page} onClick={() => { navigate(links[index]); handleCloseNavMenu(); }}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))
                : isRootPage && sections.map((section, index) => (
                    <MenuItem key={section} component={Link} to={sectionLinks[index]} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{section}</Typography>
                    </MenuItem>
                  ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex' }}>
            {!isAuthenticated ? (
              <>
                <Button
                  onClick={() => loginWithRedirect()}
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  className="nav-link-section"
                >
                  Login
                </Button>
                {isRootPage && (
                <Button
                  onClick={() => registerInterest()}
                  sx={{ my: 2, color: "white", display: "block" }}
                  className="nav-link-bubble"
                >
                  Try for Free
                </Button>
                )}
              </>
            ) : (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar className="Avatar">{user?.name ? getInitials(user.name) : ''}</Avatar>
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem 
                  key={setting} 
                  onClick={setting === 'Company Profile' ? handleProfileClick : setting === 'Logout' ? handleLogout : handleCloseUserMenu}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;