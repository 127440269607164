// CRM.jsx
import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox, 
  FormControlLabel,
  Tooltip
} from "@mui/material";
import { UserContext } from "../components/UserContext"; // Context for user information
import { DataGrid, GridActionsCellItem, GridColumnMenuFilterItem, GridColumnMenuSortItem, getGridStringOperators } from "@mui/x-data-grid"; // MUI DataGrid components
import { useAuth0 } from '@auth0/auth0-react'; // Auth0 for authentication
import CloseIcon from '@mui/icons-material/Close'; // Icon for closing elements
import EditIcon from '@mui/icons-material/Edit'; // Icon for edit action
import DeleteIcon from '@mui/icons-material/Delete'; // Icon for delete action
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';  // Icon for pushing action
import { useNavigate } from 'react-router-dom'; // Hook for navigation

const CRM = () => {
  // Initialize navigation hook
  const navigate = useNavigate();

  // Extract user information from Auth0
  const { user } = useAuth0();
  
  // Extract premium user information from context
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  
  // State variables for handling confirmation dialogs and deletions
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  
  // State for storing CRM data
  const [crmData, setCrmData] = useState([]);
  
  // State variables for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State variables for add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [editedItem, setEditedItem] = useState({
    name: "",
    address: "",
    contact: "",
    email: "",
    phone: "",
    vat_number: "",
    company_number: "",
  });


  // Snackbar state variable set and include severity
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" // Can be "success", "error", "warning", "info"
  });
  
  // State for managing the filter model in DataGrid
  const [filterModel, setFilterModel] = useState({ items: [] });

  /**
   * Fetch CRM data from the backend API.
   * Maps 'companyId' to 'id' to ensure each row has a unique identifier required by DataGrid.
   */
  const fetchCrmData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/crm/customer-details/?owner=${premiumKey}`);
      if (!response.ok) throw new Error('Failed to fetch CRM data');
      const data = await response.json();
      console.log('raw data', data);
      
      // Initialize a Set to track unique companyIds
      const idSet = new Set();
      
      // Map each item to include a unique 'id' based on 'companyId'
      const mappedData = data.items.map((item) => {
        if (!item.companyId) {
          console.warn('Item missing companyId:', item);
          // Assign a temporary unique id if 'companyId' is missing
          return { ...item, id: `missing-id-${Math.random()}` };
        }
        if (idSet.has(item.companyId)) {
          console.warn('Duplicate companyId found:', item.companyId);
          // Assign a unique id by appending a random number if 'companyId' is duplicated
          return { ...item, id: `duplicate-id-${item.companyId}-${Math.random()}` };
        }
        idSet.add(item.companyId);
        return { ...item, id: item.companyId };
      });
      
      console.log('Mapped CRM Data:', mappedData);
      setCrmData(mappedData); // Update CRM data state
      setLoading(false); // Set loading to false after data is fetched
    } catch (err) {
      setError(err.message); // Set error message
      setLoading(false); // Set loading to false in case of error
    }
  };

  // Fetch CRM data when the component mounts
  useEffect(() => {
    fetchCrmData();
  }, []);

  /**
   * Handle opening the add/edit popup.
   * If an 'id' is provided, populate the form with existing data for editing.
   * Otherwise, reset the form for adding a new customer.
   * @param {string|null} id - The unique identifier of the customer to edit, or null for adding.
   */
  const handleAddEdit = (id = null) => {
    if (id !== null) {
      const itemToEdit = crmData.find(item => item.id === id);
      console.log('crmData', crmData);
      console.log('itemToEdit', itemToEdit);
      setEditedItem({ ...itemToEdit }); // Populate form with existing data
    } else {
      // Reset form fields for adding a new customer
      setEditedItem({
        name: "",
        company_address: "",
        contact: "",
        email: "",
        telephone: "",
        vat_number: "",
        company_number: "",
      });
    }
    setOpenAddEditPopup(true); // Open the add/edit dialog
  };

  /**
   * Handle initiating the deletion of a customer.
   * Opens a confirmation dialog.
   * @param {string} id - The unique identifier of the customer to delete.
   */
  const handleDelete = (id) => {
    setItemToDeleteId(id); // Set the id of the item to delete
    setConfirmDelete(false); // Reset the confirmation checkbox
    setOpenConfirmationDialog(true); // Open the confirmation dialog
  };

  /**
   * Confirm and execute the deletion of a customer.
   * Sends a DELETE request to the backend API and updates local state upon success.
   */
  const handleConfirmDelete = async () => {
    if (confirmDelete && itemToDeleteId !== null) {
      try {
        const updatedData = crmData.filter(item => item.id !== itemToDeleteId);
        await updateCustomerData(updatedData);
        setSnackbar({
          open: true,
          message: "Customer entry successfully deleted from CRM",
          severity: "success"
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to delete customer entry",
          severity: "error"
        });
      } finally {
        setOpenConfirmationDialog(false);
        setItemToDeleteId(null);
      }
    }
  };


  /**
   * Handle initiating the creation of a prospect.
   */
  const handleCreateProspect = (id) => {
    console.log(`CRM - Initiating prospect creation for id: ${id}`);
    const company = crmData.find(item => item.id === id);
    if (company) {
      navigate(`/prospects?action=add&companyId=${company.companyId}&companyName=${encodeURIComponent(company.name)}`);
    }
  };

  /**
   * Handle changes in the add/edit form fields.
   * Updates the 'editedItem' state accordingly.
   * @param {string} field - The form field being updated.
   * @param {string} value - The new value of the form field.
   */
  const handleChange = (field, value) => {
    setEditedItem(prevItem => ({
      ...prevItem,
      [field]: value
    }));
  };

  /**
   * Handle the submission of the add/edit form.
   * Sends a POST or PUT request to the backend API
   * Refreshes the CRM data upon successful submission.
   */
  const handleSubmit = async () => {
    try {
      let updatedData;
      if (editedItem.id !== undefined) {
        updatedData = crmData.map(item => 
          item.id === editedItem.id ? editedItem : item
        );
        await updateCustomerData(updatedData);
        setSnackbar({
          open: true,
          message: "Customer entry successfully edited",
          severity: "success"
        });
      } else {
        updatedData = [...crmData, { ...editedItem, id: crmData.length }];
        await updateCustomerData(updatedData);
        setSnackbar({
          open: true,
          message: "New customer entry successfully added to CRM",
          severity: "success"
        });
      }
      setOpenAddEditPopup(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update customer entry",
        severity: "error"
      });
    }
  };
  
  const updateCustomerData = async (newData) => {
    try {
      // Remove the 'id' field from each item
      const cleanedData = newData.map(({ id, ...item }) => ({
        name: item.name,
        contact: item.contact,
        telephone: item.telephone,
        email: item.email,
        company_address: item.company_address,
        vat_number: item.vat_number,
        company_number: item.company_number,
        companyId: item.companyId || ""  // Use existing companyId or empty string for new entries
      }));
      
      const requestBody = {
        owner_org: premiumKey,
        items: cleanedData
      };

      console.log("Sending data to backend:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/crm/customer-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      const responseData = await response.json();
      console.log("Full response from server:", responseData);

      if (!response.ok) {
        let errorMessage = 'Failed to update pricing data';
        if (responseData.detail) {
          if (Array.isArray(responseData.detail)) {
            errorMessage += ': ' + responseData.detail.map(err => JSON.stringify(err)).join(', ');
          } else {
            errorMessage += ': ' + responseData.detail;
          }
        }
        throw new Error(errorMessage);
      }

      // Update local state with the response from the server
      setCrmData(newData);
      setOpenAddEditPopup(false);

      // Fetch updated data from the server
      fetchCrmData();
    } catch (error) {
      console.error("Error updating CRM data:", error);
    }
  };

  /**
   * Handle closing the Snackbar message.
   */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  /**
   * Custom Column Menu for DataGrid.
   * Restricts the column menu to only sort and filter options.
   * @param {object} props - Properties passed to the custom column menu.
   */
  const CustomColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
      <div>
        <GridColumnMenuSortItem onClick={hideMenu} column={currentColumn} />
        <GridColumnMenuFilterItem onClick={hideMenu} column={currentColumn} />
      </div>
    );
  };

  /**
   * Handle changes to the filter model from the DataGrid.
   * Updates the local 'filterModel' state to synchronize with filter bubbles.
   * @param {object} model - The new filter model.
   */
  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };

    /**
   * Handle double-click event on a DataGrid row.
   * Navigates to the Prospects page and passes the selected company's ID.
   * @param {object} params - Parameters containing row information.
   */
    const handleRowDoubleClick = (params) => {
      console.log('params', params)
      const companyId = params.row.companyId;
      const companyName = params.row.name;
      console.log('companyId', companyId)
      // Navigate to Prospects page with companyId as a query parameter
      navigate(`/prospects?companyName=${companyName}`);
    };

   /**
   * Define the columns for the DataGrid, including field names, header names, flex properties, and filter operators.
   * The 'actions' column includes edit and delete actions for each row.
   */
   const columns = [
    { 
      field: "name", 
      headerName: "Company Name", 
      flex: 1.5, 
      minWidth: 200, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "company_address", 
      headerName: "Company Address", 
      flex: 2, 
      minWidth: 300, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "contact", 
      headerName: "Main Contact", 
      flex: 0.75, 
      minWidth: 100, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "email", 
      headerName: "Contact Email", 
      flex: 1, 
      minWidth: 150, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "telephone", 
      headerName: "Contact Number", 
      flex: 1, 
      minWidth: 150, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "vat_number", 
      headerName: "VAT Number", 
      flex: 1, 
      minWidth: 150, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "company_number", 
      headerName: "Company Number", 
      flex: 1, 
      minWidth: 150, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    { 
      field: "last_active", 
      headerName: "Last Active", 
      flex: 1, 
      minWidth: 150, 
      filterOperators: getGridStringOperators().filter(op => ['contains', 'equals'].includes(op.value)) 
    },
    {
      field: "actions", // Special column for action buttons
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => { // Define actions based on row id
        return [
          <Tooltip title="Edit Customer">
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleAddEdit(id)}
            color="inherit"
          />
          </Tooltip>,
          <Tooltip title="Delete Customer">
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(id)}
            color="inherit"
          />
        </Tooltip>,
        <Tooltip title="Create Prospect">
        <GridActionsCellItem
          icon={<ArrowCircleRightIcon />}
          label="Archive"
          onClick={() => handleCreateProspect(id)}
          color="inherit"
        />
        </Tooltip>,
        ];
      }
    },
  ];

  /**
   * Create a mapping from field names to header names for display in filter bubbles.
   * Utilizes React's useMemo for performance optimization.
   */
  const fieldToHeaderName = React.useMemo(() => {
    const mapping = {};
    columns.forEach((column) => {
      mapping[column.field] = column.headerName;
    });
    return mapping;
  }, [columns]);

  /**
   * Render the applied filters as visual filter bubbles above the DataGrid.
   * Each bubble displays the header name and filter value, and includes a close icon to remove the filter.
   */
  const renderAppliedFilters = () => (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '10px' }}>
      {filterModel.items.map((filter, index) => (
        filter.value && (
          <div
            key={index}
            style={{
              backgroundColor: '#FFD700',
              borderRadius: '20px',
              padding: '5px 10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">
              {`${fieldToHeaderName[filter.field] || filter.field} - ${filter.value}`}
            </Typography>
            <IconButton
              size="small"
              onClick={() => removeFilter(index)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        )
      ))}
    </div>
  );

  /**
   * Remove a specific filter based on its index in the filter model.
   * Updates the 'filterModel' state to exclude the removed filter.
   * @param {number} filterIndex - The index of the filter to remove.
   */
  const removeFilter = (filterIndex) => {
    const newItems = [...filterModel.items];
    newItems.splice(filterIndex, 1); // Remove the filter at the specified index
    setFilterModel({ ...filterModel, items: newItems }); // Update the filter model state
  };

 

  return (
    <div className="page-container">
      {/* Page Title */}
      <Typography variant="h4">CRM Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />

      {/* Render Applied Filters as Bubbles */}
      {renderAppliedFilters()}

      <div className="crm-details-container">
        {loading ? (
          // Show a loading spinner while data is being fetched
          <CircularProgress />
        ) : error ? (
          // Display an error alert if there's an error fetching data
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Button to Add a New Customer */}
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                Add Customer
              </Button>
            </div>
            
            {/* DataGrid Displaying CRM Data */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={crmData} // Rows data
                columns={columns} // Columns configuration
                pageSize={5} // Number of rows per page
                rowsPerPageOptions={[5]} // Options for rows per page
                disableSelectionOnClick // Disable row selection on click
                filterModel={filterModel} // Controlled filter model
                onFilterModelChange={handleFilterModelChange} // Handle filter model changes
                onRowDoubleClick={handleRowDoubleClick} // Handle row double-click
                components={{
                  ColumnMenu: CustomColumnMenu, // Use custom column menu
                }}
                sx={{
                  // Styling for the DataGrid
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#DDD6CC',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'black',
                  },
                  '& .MuiDataGrid-menuIcon': {
                    color: 'black',
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    color: 'black',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Dialog for Adding/Editing a Customer */}
      <Dialog open={openAddEditPopup} onClose={() => setOpenAddEditPopup(false)}>
        <DialogTitle>{editedItem && editedItem.id ? "Edit Customer" : "Add Customer"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Customer Name"
            value={editedItem?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Company Address"
            value={editedItem?.company_address || ""}
            onChange={(e) => handleChange("company_address", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Contact Person"
            value={editedItem?.contact || ""}
            onChange={(e) => handleChange("contact", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={editedItem?.email || ""}
            onChange={(e) => handleChange("email", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Contact Number"
            value={editedItem?.telephone || ""}
            onChange={(e) => handleChange("telephone", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="VAT Number"
            value={editedItem?.vat_number || ""}
            onChange={(e) => handleChange("vat_number", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Company Number"
            value={editedItem?.company_number || ""}
            onChange={(e) => handleChange("company_number", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddEditPopup(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Confirming Deletion of a Customer */}
      <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this customer entry?</Typography>
          <FormControlLabel
            control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
            label="Yes, I want to delete this customer"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error" 
            disabled={!confirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Displaying Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CRM;

