import React, { useState, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { PaymentFetch } from "../components/Backend Routes/fetchPayment";

const columns: GridColDef[] = [
  { field: "bank", headerName: "Bank Name", flex: 1.5, minWidth: 250 },
  { field: "bank_address", headerName: "Bank Address", flex: 3, minWidth: 300 },
  { field: "sort_code", headerName: "Sort Code", flex: 1.5, minWidth: 250 },
  { field: "account_number", headerName: "Account Number", flex: 1.5, minWidth: 300 },
  { field: "terms", headerName: "Payment Terms", flex: 1.5, minWidth: 250 },
];

const PaymentDetails = () => {
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const { payment, loading, error, refetchPayment } = PaymentFetch(premiumKey);
  
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [editedPayment, setEditedPayment] = useState({
    owner_org: premiumKey,
    bank: "",
    bank_address: "",
    sort_code: "",
    account_number: "",
    terms: "",
  });
  const navigate = useNavigate();

  const handleAddEdit = () => {
    setEditedPayment(payment ? 
      { ...payment, owner_org: premiumKey } : 
      {
        owner_org: premiumKey,
        name: "",
        address: "",
        sort_code: "",
        account_number: "",
        terms: "",
      }
    );
    setOpenAddEditPopup(true);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleChange = (field, value) => {
    setEditedPayment(prevPayment => ({
      ...prevPayment,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    const paymentToSubmit = {
      ...editedPayment,
    };
    console.log('updated payment:', paymentToSubmit);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/payment-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentToSubmit),
      });
      if (!response.ok) {
        throw new Error('Failed to update payment details');
      }
      await refetchPayment();
      setMessageText("Payment details updated successfully");
      setShowMessage(true);
      setOpenAddEditPopup(false);
    } catch (error) {
      console.error("Error updating payment details:", error);
      setMessageText("Failed to update payment details");
      setShowMessage(true);
    }
  };


  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const returnProfile = () => {
    navigate('/profile');
  };

  const rows = payment ? [{ id: 1, ...payment }] : [];

  return (
    <div className="page-container">
      <Tooltip title="Back to Payment Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Payment Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <div className="payment-details-container">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEdit}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                {payment ? "Edit Payment Details" : "Add Payment Details"}
              </Button>
            </div>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={1}
                disableSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* Add/Edit Payment Details Popup */}
      <Dialog open={openAddEditPopup} onClose={() => setOpenAddEditPopup(false)}>
        <DialogTitle>{payment ? "Edit Payment Details" : "Add Payment Details"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Bank"
            value={editedPayment.bank}
            onChange={(e) => handleChange("bank", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Bank Address"
            value={editedPayment.bank_address}
            onChange={(e) => handleChange("bank_address", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Sort Code"
            value={editedPayment.sort_code}
            onChange={(e) => handleChange("sort_code", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Account Number"
            value={editedPayment.account_number}
            onChange={(e) => handleChange("account_number", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Payment Terms"
            value={editedPayment.terms}
            onChange={(e) => handleChange("terms", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddEditPopup(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showMessage}
        autoHideDuration={2000}
        onClose={handleCloseMessage}
        message={messageText}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default PaymentDetails;