// Quotes.jsx
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext"; // Context for user information
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenuFilterItem,
  GridColumnMenuSortItem,
  getGridStringOperators,
} from "@mui/x-data-grid"; // MUI DataGrid components
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Hooks for navigation and accessing location
import { useAuth0 } from "@auth0/auth0-react"; // Auth0 for authentication
import CloseIcon from "@mui/icons-material/Close"; // Icon for closing elements
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from "@mui/icons-material/Archive"; // Icon for archive action
import EditIcon from "@mui/icons-material/Edit"; // Icon for edit action
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete action
import {
  fetchQuoteData,
  fetchProspects,
  archiveQuote
} from "../components/Backend Routes/QuoteFetch"; // Backend API functions
import QuoteDialog from '../components/Quote Components/QuoteDialog';


const Quotes = () => {
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location:', location.search);

  // Ref flag to indicate programmatic filter updates
  const initialFilterSet = useRef(false);

  // Extract user information from Auth0
  const { user } = useAuth0();

  // Extract premium user information from context
  const { isPremiumUser, premiumKey } = useContext(UserContext);

  // State variables for handling confirmation dialogs, deletions, downloading and archiving
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmationArchiveDialog, setOpenConfirmationArchiveDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToArchiveId, setItemToArchiveId] = useState(null); // State for archiving prospects
  const [itemToDownloadId, setItemToDownloadId] = useState(null); // State for downloading quotes

  // State for storing quote templatedata
  const [quoteTemplate, setQuoteTemplate] = useState([]);
  
  // State for storing prospect and CRM companies data
  const [quoteData, setQuoteData] = useState([]);
  const [prospectData, seProspectData] = useState([]);

  // State variables for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialCompanyId, setInitialCompanyId] = useState(null);
  const [initialProjectId, setInitialProjectId] = useState(null);
  const [quoteAction, setquoteAction] = useState('');

  // State variables for add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [editedItem, setEditedItem] = useState({
    quoteId: "",
    companyId: "",
    projectId: "",
    companyName: "",
    projectName: "",
    quote_number: "",
    order_number: "",
    name: "",
    status: "",
    last_updated: new Date(),
  });

  // State variables for displaying messages to the user
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");

  // State for managing the filter model in DataGrid
  const [filterModel, setFilterModel] = useState({ items: [] });

  // Snackbar state variable set and include severity
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" // Can be "success", "error", "warning", "info"
  });

   /**
   * Create a mapping from companyId to companyName for quick lookup.
   * Utilizes React's useMemo for performance optimization.
   */
   const companyLookup = useMemo(() => {
    return (quoteData || []).reduce((acc, quote) => {
      if (quote && quote.companyId && quote.companyName) {
        acc[quote.companyId] = quote.companyName;
      }
      return acc;
    }, {});
  }, [quoteData]);
  
  /**
   * Fetching prospect and CRM companies data from the backend API.
   * Ensures that each prospect has a unique 'id' required by DataGrid.
   */
  useEffect(() => {
    const loadData = async () => {
      try {
        const quotes = await fetchQuoteData(premiumKey);
        const prospects = await fetchProspects(premiumKey);
        console.log('quotes', quotes);
        console.log('prospects', prospects);

        const idSet = new Set();
        
        const mappedQuotes = quotes.map((quote) => {
          let id = quote.quoteId && quote.quoteId.trim() !== '' ? quote.quoteId : `project-${quote.projectId}` || `temp-${Math.random().toString(36)}`;
          while (idSet.has(id)) {
            id = `${id}-${Math.random().toString(36)}`;
          }
          idSet.add(id);

          // Find corresponding prospect
          const correspondingProspect = prospects.find(
            prospect => prospect.companyId === quote.companyId && prospect.projectId === quote.projectId
          );

          return {
            ...quote,
            id,
            companyName: correspondingProspect?.companyName || 'Unknown Company',
            projectName: correspondingProspect?.projectName || 'Unknown Project'
          };
        });

        setQuoteData(mappedQuotes);
        seProspectData(prospects);
      } catch (err) {
        setError(err.message);
        setQuoteData([]);
        console.error("Quotes - Error fetching data:", err);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    };
    loadData();
  }, [premiumKey]);


  /**
   * Extract 'companyId' from URL query parameters and apply it as a filter.
   * Prevents infinite loops by checking if the filter is already set.
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const companyId = params.get("companyId");
    const projectId = params.get("projectId");
    const companyName = params.get("companyName");
    const action = params.get('action');
    
    console.log('company id retrieved from CRM', companyId);
    console.log('companyName retrieved from CRM', companyName);
    
    if (action === 'add' && companyId && projectId) {
      setInitialCompanyId(companyId);
      setInitialProjectId(projectId);
    }

    if (companyName) {
      console.log('Quotes - Applying initial filter for companyId:', companyId);
      console.log('Quotes - Corresponding company name:', companyLookup[companyId]);
      setFilterModel({
        items: [
          {
            field: "companyName",
            operatorValue: "equals",
            value: companyName,
          },
        ],
      });
      initialFilterSet.current = true;
    }
  }, [location.search]);


     /**
   * If data is loaded and an additional initialCompanyId is set from
   * the URL search paramters it then sets companyId of EditedItem for the popup to prepopulate
   */
     useEffect(() => {
      if (dataLoaded && initialCompanyId && initialProjectId) {
        const prospect = quoteData.find(c => c.companyId === initialCompanyId);
        if (prospect) {
          setEditedItem({
            quoteId: "",
            companyId: prospect.companyId,
            projectId: prospect.projectId, 
            name: "",
            status: "",
          });
          setOpenAddEditPopup(true);
        }
        setInitialCompanyId(null); // Reset to prevent reopening
      }
    }, [dataLoaded, initialCompanyId, quoteData]);

  /**
   * Handle opening the add/edit popup.
   * If an 'id' is provided, populate the form with existing data for editing.
   * Otherwise, reset the form for adding a new prospect.
   * @param {string|null} id - The unique identifier of the prospect to edit, or null for adding.
   */
  const handleAddEdit = (id = null) => {
    if (id !== null && quoteData) {
      const itemToEdit = quoteData.find((item) => item.id === id);
      console.log("Quotes - Editing item:", itemToEdit);
      
      // Find the corresponding prospect data
      const correspondingProspect = prospectData.find(
        prospect => prospect.companyId === itemToEdit.companyId && prospect.projectId === itemToEdit.projectId
      );

      setEditedItem({
        ...itemToEdit,
        companyName: correspondingProspect ? correspondingProspect.companyName : '',
        projectName: correspondingProspect ? correspondingProspect.projectName : ''
      });
    } else {
      // Reset form fields for adding a new quote
      setEditedItem({
        quoteId: "",
        companyId: "",
        projectId: "",
        companyName: "",
        projectName: "",
        quote_number: "",
        order_number: "",
        name: "",
        status: "",
        last_updated: new Date(),
      });
      console.log("Quotes - Adding new quote.");
    }
    
    // Only open the dialog if the template has been loaded
    if (quoteTemplate) {
      setOpenAddEditPopup(true);
    } else {
      console.error("Quote template not loaded yet");
      // Optionally, show an error message to the user
    }
  };

  /**
   * Handle initiating the deletion of a quote.
   * Opens a confirmation dialog.
   * @param {string} id - The unique identifier of the prospect to delete.
   */
  const handleDelete = (id) => {
    console.log(`Quotes - Initiating deletion for id: ${id}`);
    setItemToDeleteId(id); // Set the id of the item to delete
    setConfirmDelete(false); // Reset the confirmation checkbox
    setOpenConfirmationDialog(true); // Open the confirmation dialog
  };


/**
 * Handle initiating the archiving of a quote.
 * Opens an archive confirmation dialog.
 * @param {string} id - The unique identifier of the quote to archive.
 */
const handleArchive = (id) => {
  console.log(`Quotes - Initiating archive for id: ${id}`);
  setItemToArchiveId(id);
  setConfirmArchive(false); // Reset the confirmation checkbox
  setOpenConfirmationArchiveDialog(true); // Open the confirmation dialog
};

/**
 * Confirm and execute the archive of a quote.
 * Sends an Archive request to the backend API and updates local state upon success.
 */
const handleConfirmArchive = async () => {
  if (confirmArchive && itemToArchiveId !== null) {
    try {
      console.log('Archiving quote with ID:', itemToArchiveId);
      
      // Send archive request to the backend
      await archiveQuote(premiumKey, itemToArchiveId);

      // Close the confirmation dialog
      setOpenConfirmationArchiveDialog(false);

      // Update local state to reflect the change
      setQuoteData((prevData) =>
        prevData.map((item) =>
          item.id === itemToArchiveId ? { ...item, status: "Archived" } : item
        )
      );

      // Show success message
      setMessageText("Quote archived successfully");
      setShowMessage(true);

    } catch (error) {
      console.error("Error archiving quote:", error);
      setMessageText(`Failed to archive quote: ${error.message}`);
      setShowMessage(true);
    } finally {
      // Reset the archive-related state
      setItemToArchiveId(null);
      setConfirmArchive(false);
    }
  }
};

    /**
   * Handle initiating the archiving of a quote.
   * Opens an download confirmation dialog or performs archiving logic.
   * @param {string} id - The unique identifier of the prospect to archive.
   */
  const handleDownload = (id) => {
    console.log(`Quotes - Initiating download for id: ${id}`);
    setItemToDownloadId(id); // Set the id of the item to archive
  };

  /**
   * Confirm and execute the deletion of a quote.
   * Sends a DELETE request to the backend API and updates local state upon success.
   */
  // const handleConfirmDelete = () => {
  //   if (confirmDelete && itemToDeleteId !== null) {
  //     const updatedData = quoteData.filter(item => item.id !== itemToDeleteId);
  //     // Send filtered data without the deleted row to the backend
  //     updateQuotes(updatedData);
  //     // Update local state
  //     setQuoteData(updatedData);
  //     setOpenConfirmationDialog(false);
  //     setItemToDeleteId(null);
  //   }
  // };

  const handleConfirmDelete = async () => {
    if (confirmDelete && itemToDeleteId !== null) {
      try {
        const updatedData = quoteData.filter(item => item.id !== itemToDeleteId);
        // Send filtered data without the deleted row to the backend
        await updateQuotes(updatedData);
        // Update local state
        setQuoteData(updatedData);
        setSnackbar({
          open: true,
          message: "Quote successfully deleted from Quotes",
          severity: "success"
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to delete Quote entry",
          severity: "error"
        });
      } finally {
        setOpenConfirmationDialog(false);
        setItemToDeleteId(null);
      }
    }
  };

  /**
   * Handle changes in the add/edit form fields.
   * Updates the 'editedItem' state accordingly.
   * @param {string} field - The form field being updated.
   * @param {string} value - The new value of the form field.
   */
  const handleChange = (field, value) => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
    console.log(`Prospects - Updated field '${field}' to '${value}'.`);
  };


  /**
   * Handle the submission of the add/edit form.
   * Updates local state and sends request to backend API.
   * @param {Object} formData - The form data from the QuoteDialog
   * @param {string} action - Either 'draft' or 'finalize'
   */
  const handleSubmit = async (formData) => {
    try {
      setquoteAction(formData.action)
      // Prepare the new quote object
      let newQuote = {
        name: formData.name,
        creator: user.email || "",
        last_updated: new Date().toISOString(),
        issue_date: formData.issue_date ? `${formData.issue_date}T00:00:00` : null,
        companyId: formData.companyId,
        projectId: formData.projectId,
        quote_number: formData.quote_number,
        order_number: formData.order_number,
        status: formData.status,
        lineItems: formData.lineItems,
        quoteTotal: formData.quoteTotal,
        quoteId: formData.quoteId || `new-${Date.now()}`,
        id: formData.quoteId || `new-${Date.now()}`, // Add this line
      };

      // Validation for finalized quotes
      if (quoteAction === 'finalize') {
        const requiredFields = ['companyId', 'projectId', 'name', 'status', 'telephone', 'vat_number', 'company_number', 'quoteTotal', 'site_address', 'company_address'];
        for (let field of requiredFields) {
          if (!newQuote[field]) {
            throw new Error(`${field} is required for finalized quotes`);
          }
        }
        if (newQuote.lineItems.length === 0) {
          throw new Error('At least one line item is required for finalized quotes');
        }
      }

      // Find corresponding prospect
      const correspondingProspect = prospectData.find(
        prospect => prospect.companyId === newQuote.companyId && prospect.projectId === newQuote.projectId
      );

      // Add companyName and projectName to the newQuote
      newQuote = {
        ...newQuote,
        companyName: correspondingProspect?.companyName || 'Unknown Company',
        projectName: correspondingProspect?.projectName || 'Unknown Project'
      };

      let updatedData;
      if (formData.quoteId) {
        // Editing existing quote
        updatedData = quoteData.map(item => 
          item.quoteId === formData.quoteId ? { ...newQuote, id: item.id } : item
        );
        await updateQuotes(updatedData);
        setSnackbar({
          open: true,
          message: "Quote successfully edited",
          severity: "success"
        });
      } else {
        // Adding new quote
        updatedData = [...quoteData, { ...newQuote, id: newQuote.quoteId }];
        await updateQuotes(updatedData);
        setSnackbar({
          open: true,
          message: "New quote successfully added to Quotes",
          severity: "success"
        });
      }

      // Update local state
      setQuoteData(updatedData);

      // Close the dialog and show success message
      setOpenAddEditPopup(false);
      setMessageText(`Quote ${quoteAction === 'draft' ? 'saved as draft' : 'finalized'} successfully`);
      setShowMessage(true);

    } catch (error) {
      console.error("Error updating Quote data:", error);
      setSnackbar({
        open: true,
        message: "Failed to update quote entry",
        severity: "error"
      });
    }
  };

  /**
   * Send updated quote data to the backend API.
   * @param {Array} newData - The updated array of quote data.
   */
  const updateQuotes = async (newData) => {
    try {
      // Remove the 'id' field and transform data for backend
      const cleanedData = newData.map(({ id, ...item }) => ({
        ...item,
        quoteId: item.quoteId || id, // Use the id as quoteId if quoteId is empty
      }));
      
      const requestBody = {
        owner_org: premiumKey,
        items: cleanedData
      };

      console.log("Sending data to backend:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/quote/quote-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to update quote data: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("Full response from server:", responseData);

      // Note: We don't set quoteData here as it's already updated in handleSubmit

    } catch (error) {
      console.error("Error updating Quote data:", error);
      throw error; // Propagate error to be handled in handleSubmit
    }
  };

  /**
 * Handle closing the Snackbar message.
 */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };


  /**
   * Custom Column Menu for DataGrid.
   * Restricts the column menu to only sort and filter options.
   * @param {object} props - Properties passed to the custom column menu.
   */
  const CustomColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
      <div>
        <GridColumnMenuSortItem onClick={hideMenu} column={currentColumn} />
        <GridColumnMenuFilterItem onClick={hideMenu} column={currentColumn} />
      </div>
    );
  };

  /**
   * Define the columns for the DataGrid, including field names, header names, flex properties, and filter operators.
   * The 'actions' column includes edit, delete, and archive actions for each row.
   */
  const columns = useMemo(
    () => [
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "projectName",
        headerName: "Project Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "name",
        headerName: "Quote Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "most_recent",
        headerName: "Most Recent Activity",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "actions", // Special column for action buttons
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <Tooltip title="Edit Quote">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleAddEdit(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete Quote">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Archive Quote">
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label="Archive"
              onClick={() => handleArchive(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Download Quote">
            <GridActionsCellItem
              icon={<DownloadIcon />}
              label="Download Quote"
              onClick={() => handleDownload(id)}
              color="inherit"
            />
          </Tooltip>
          ];
        },
      },
    ],
    [companyLookup, handleAddEdit, handleDelete, handleArchive] // Dependencies for useMemo
  );

  /**
   * Create a mapping from field names to header names for display in filter bubbles.
   * Utilizes React's useMemo for performance optimization.
   */
  const fieldToHeaderName = useMemo(() => {
    const mapping = {};
    columns.forEach((column) => {
      mapping[column.field] = column.headerName;
    });
    return mapping;
  }, [columns]);

  /**
   * Render the applied filters as visual filter bubbles above the DataGrid.
   * Each bubble displays the header name and filter value, and includes a close icon to remove the filter.
   */
  const renderAppliedFilters = () => (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "10px" }}>
      {filterModel.items.map((filter, index) =>
        filter.value ? (
          <div
            key={index}
            style={{
              backgroundColor: "#FFD700",
              borderRadius: "20px",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              {`${fieldToHeaderName[filter.field] || filter.field} - ${
                filter.field === "companyId" && companyLookup ? companyLookup[filter.value] || filter.value : filter.value
              }`}
            </Typography>
            <IconButton size="small" onClick={() => removeFilter(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ) : null
      )}
    </div>
  );

  /**
   * Remove a specific filter based on its index in the filter model.
   * Updates the 'filterModel' state to exclude the removed filter.
   * @param {number} filterIndex - The index of the filter to remove.
   */
  const removeFilter = (filterIndex) => {
    console.log(`Prospects - Removing filter at index: ${filterIndex}`);
    const newItems = [...filterModel.items];
    newItems.splice(filterIndex, 1); // Remove the filter at the specified index
    setFilterModel({ ...filterModel, items: newItems }); // Update the filter model state
  };

  /**
   * Handle changes to the filter model from the DataGrid.
   * Updates the local 'filterModel' state to synchronize with filter bubbles.
   * @param {object} model - The new filter model.
   */
  const handleFilterModelChange = (newModel) => {
    console.log("Prospects - Filter Model Changed:", newModel);
    // Only update if the new model is different from the current one
    setFilterModel((prevModel) => {
      if (JSON.stringify(prevModel) !== JSON.stringify(newModel)) {
        return newModel;
      }
      return prevModel;
    });
  };

        /**
   * Handle double-click event on a DataGrid row.
   * Navigates to the Prospects page and passes the selected company's ID.
   * @param {object} params - Parameters containing row information.
   */
  const handleRowDoubleClick = (params) => {
    console.log('params double click', params)
    const companyName = params.row.companyName;
    // Navigate to Prospects page with companyName as a query parameter
    navigate(`/live-projects?companyName=${companyName}`);
  };

  /**
   * Render the Prospect Details page, including the DataGrid and dialogs for add/edit/delete operations.
   */
  return (
    <div className="page-container">
      {/* Page Title */}
      <Typography variant="h4">Quote Details</Typography>
      <hr
        style={{
          borderTop: "2px solid black",
          borderBottom: "2px solid black",
        }}
      />

      {/* Render Applied Filters as Bubbles */}
      {renderAppliedFilters()}

      <div className="crm-details-container">
        {loading ? (
          // Show a loading spinner while data is being fetched
          <CircularProgress />
        ) : error ? (
          // Display an error alert if there's an error fetching data
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Button to Add a New Prospect */}
            <div style={{ marginBottom: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: "15px" }}
              >
                Add Quote
              </Button>
            </div>

            {/* DataGrid Displaying Prospect Data */}
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={quoteData} // Rows data
                columns={columns} // Columns configuration
                pageSize={10} // Number of rows per page
                rowsPerPageOptions={[5, 10, 20]} // Options for rows per page
                disableSelectionOnClick // Disable row selection on click
                filterModel={filterModel} // Controlled filter model
                onRowDoubleClick={handleRowDoubleClick} // Handle row double-click
                onFilterModelChange={handleFilterModelChange} // Handle filter model changes
                components={{
                  // Use custom column menu to limit options
                  ColumnMenu: CustomColumnMenu,
                }}
                sx={{
                  // Styling for the DataGrid
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#DDD6CC",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "black",
                  },
                  "& .MuiDataGrid-menuIcon": {
                    color: "black",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    color: "black",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Dialog for Adding/Editing a quote */}
      <QuoteDialog
      open={openAddEditPopup}
      onClose={() => setOpenAddEditPopup(false)}
      template={quoteTemplate}
      editedItem={editedItem}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      prospectData={prospectData}
    />

      {/* Dialog for Confirming Deletion of a Quote */}
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Quote entry?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
              />
            }
            label="Yes, I want to delete this quote"
          />
        </DialogContent>
        <DialogActions>
          {/* Cancel Button */}
          <Button onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </Button>
          {/* Delete Button */}
          <Button
            onClick={handleConfirmDelete}
            color="error"
            disabled={!confirmDelete} // Disable until confirmation checkbox is checked
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Confirming Archiving of a Quote */}
      <Dialog
        open={openConfirmationArchiveDialog}
        onClose={() => setOpenConfirmationArchiveDialog(false)}
      >
        <DialogTitle>Confirm Archiving</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to archive this Quote entry?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmArchive}
                onChange={(e) => setConfirmArchive(e.target.checked)}
              />
            }
            label="Yes, I want to archive this quote"
          />
        </DialogContent>
        <DialogActions>
          {/* Cancel Button */}
          <Button onClick={() => setOpenConfirmationArchiveDialog(false)}>
            Cancel
          </Button>
          {/* Archive Button */}
          <Button
            onClick={handleConfirmArchive}
            color="error"
            disabled={!confirmArchive} // Disable until confirmation checkbox is checked
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Displaying Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Quotes;
