// AddUser.js
export const AddUser = async (userFields, email) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/team/add-user/${email}`;

  
    try {
      const response = await fetch(`${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userFields),
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Receive response from backend
      const responseData = await response.json();
      console.log('Deletion response:', responseData);
  
      // Return the response data
      return responseData;
    } catch (error) {
      console.error('Deletion error:', error);
      // Rethrow the error to be caught in the calling component
      throw error;
    }
  };