// DeleteAssignedSlate.js
export const DeleteAssignedSlate = async (url, slate_id) => {
  try {
    const response = await fetch(`${url}/${slate_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};