// // Company Details Page
// import React, { useState, useEffect } from "react";
// import {
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Button,
//   Menu,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Tooltip,
// } from "@mui/material";
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import { UsersFetch } from "../components/Backend Routes/fetchUsers";
// import { DeleteUsers } from '../components/Backend Routes/DeleteTeamUsers';
// import { UpdateUsers } from '../components/Backend Routes/UpdateTeamUsers';
// import { AddUser } from '../components/Backend Routes/AddUser';
// import { useAuth0 } from '@auth0/auth0-react';
// import CloseIcon from '@mui/icons-material/Close';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useNavigate } from 'react-router-dom';

// const columns: GridColDef[] = [
//   { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
//   { field: "name", headerName: "Name", flex: 2, minWidth: 250 },
//   { field: "email", headerName: "Email", flex: 2, minWidth: 300 },
//   { field: "subscription_tier", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
// ];

// const TeamManagement = () => {
//   const { user } = useAuth0();
//   const [editEmailError, setEditEmailError] = useState("");
//   const [addEmailError, setAddEmailError] = useState("");
//   const { users, premiumKey, loading, error, refetchUsers } = UsersFetch(user.email);
//   console.log('users: ', users)
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [AddPopup, setAddPopup] = useState(false);
//   const [openEditPopup, setOpenEditPopup] = useState(false);
//   const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const [showDeleteMessage, setShowDeleteMessage] = useState(false);
//   const [showEditMessage, setShowEditMessage] = useState(false);
//   const [editedUser, setEditedUser] = useState({
//     name: "",
//     // email: "",
//     organization_id: [],
//   });
//   const [newUser, setNewUser] = useState({
//     name: "",
//     email: "",
//     organization_id: [],
//   });
//   const navigate = useNavigate();


//   console.log('premiumKey: ', premiumKey)

  
//   useEffect(() => {
//     if (selectedUsers.length === 1) {
//       const selectedUser = users.find((u) => u === selectedUsers[0]);
//       if (selectedUser) {
    
//         if (premiumKey) {
//           const filteredOrganizationId = selectedUser.organization_id.filter(
//             (orgId) => Object.keys(orgId)[0] === premiumKey
//           );

//           console.log('filteredOrganizationId: ', filteredOrganizationId[0][premiumKey])
    
//           setEditedUser({
//             name: selectedUser.name,
//             email: selectedUser.email,
//             organization_id: filteredOrganizationId[0][premiumKey],
//           });
//         } else {
//           setEditedUser({
//             name: selectedUser.name,
//             email: selectedUser.email,
//             organization_id: [],
//           });
//         }
//       }
//     } else {
//       setEditedUser({
//         name: "",
//         email: "",
//         organization_id: [],
//       });
//     }
//   }, [selectedUsers, users, user]);


//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleAdd = () => {
//     setAddPopup(true);
//   };

//   const handleEdit = () => {
//     setOpenEditPopup(true);
//   };

//   const handleDelete = () => {
//     setOpenDeleteConfirmation(true);
//   };

//   const handleSelectionChange = (selection) => {
//     const selectedUserData = selection.map((rowIndex) => users[rowIndex-1]);
//     setSelectedUsers(selectedUserData);
//     console.log('Selected Users: ', selectedUserData);
//   };

//   const handleNewUserChange = (field, value) => {
//     setNewUser((prevUser) => ({
//       ...prevUser,
//       [field]: value,
//     }));
  
//     if (field === "email") {
//       if (!validateEmail(value)) {
//         setAddEmailError("Please enter a valid email address");
//       } else {
//         setAddEmailError("");
//       }
//     }
//   };

//   const handleAddSubmit = async () => {
//     try {
//       const subscriptionTier = newUser.organization_id[0];
//       await AddUser({ ...newUser, organization_id: [subscriptionTier] }, user.email);
//       console.log()
//       // Show success message
//       setShowEditMessage(true);
//       // Update Table
//       refetchUsers();
//       setAddPopup(false);
//       setNewUser({
//         name: "",
//         email: "",
//         organization_id: [],
//       });
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };

//   const handleEditChange = (field, value) => {
//     setEditedUser((prevUser) => ({
//       ...prevUser,
//       [field]: value,
//     }));
  
//     if (field === "email") {
//       if (!validateEmail(value)) {
//         setEditEmailError("Please enter a valid email address");
//       } else {
//         setEditEmailError("");
//       }
//     }
//   };

//   const handleEditSubmit = async () => {
//     try {
//       const subscriptionTier = editedUser.organization_id[0];
//       await UpdateUsers({ ...editedUser, organization_id: [subscriptionTier] }, user.email);
//       // Show success message
//       setShowEditMessage(true);
//       // Update Table
//       refetchUsers();
//       setOpenEditPopup(false);
//       setSelectedUsers([]);
//     } catch (error) {
//       console.error("Error updating users:", error);
//     }
//   };

//   const handleDeleteConfirm = async () => {
//     if (confirmDelete) {
//       try {
//         const databaseIds = selectedUsers.map((user) => user.database_id);
//         console.log('databaseIds: ', databaseIds);
//         await DeleteUsers(databaseIds, premiumKey);
//         // Show success message
//         setShowDeleteMessage(true);
//         // Close the confirmation dialog and update slates
//         refetchUsers();
//         setOpenDeleteConfirmation(false);
//         setConfirmDelete(false);
//         setSelectedUsers([]);
//       } catch (error) {
//         console.error("Error deleting users:", error);
//       }
//     }
//   };

//   const handleCloseSuccessMessage = () => {
//     setShowDeleteMessage(false);
//     setShowEditMessage(false);
//   };

//   const validateEmail = (email) => {
//     const pattern = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
//     return pattern.test(email);
//   };

//   const returnProfile = () => {
//     navigate('/profile');
//   };

// // Ensure that users is an array before mapping
// const rows = Array.isArray(users)
//   ? users.map((user, index) => ({
//       id: index + 1, // Assuming user IDs are unique or use index as ID
//       name: user.name,
//       email: user.email,
//       subscription_tier: user.organization_id && Array.isArray(user.organization_id) && user.organization_id.length > 0
//         ? Object.values(user.organization_id[0])[0]
//         : "",
//     }))
//   : [];

//   return (
//     <div className="page-container">
//       <Tooltip title="Back to Company Profile">
//         <IconButton onClick={() => returnProfile()}>
//           <ArrowBackIcon />
//         </IconButton>
//       </Tooltip>
//       <Typography variant="h4">Company Details</Typography>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
//       <div className="admin-table-container">
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Alert severity="error">{error}</Alert>
//         ) : premiumKey ? (
//           <>
//             <div style={{ marginBottom: '16px' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleClick}
//                 className="standard-button"
//                 disabled={selectedUsers.length === 0}
//                 style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary', marginRight: '15px' }}
//               >
//                 Actions
//               </Button>
//               <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
//                 {selectedUsers.length === 1 && <MenuItem onClick={handleEdit}>Edit Users</MenuItem>}
//                 <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
//               </Menu>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAdd}
//                 className="standard-button"
//                 disabled={selectedUsers.length > 0}
//                 style={{ backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' }}
//               >
//                 Add Company
//               </Button>
//             </div>
//             <div style={{ height: 500, width: '100%' }}>
//               <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 initialState={{
//                   pagination: {
//                     paginationModel: { page: 0, pageSize: 5 },
//                   },
//                 }}
//                 pageSizeOptions={[5, 10]}
//                 checkboxSelection
//                 onRowSelectionModelChange={handleSelectionChange}
//                 getRowClassName={() => 'table-row'}
//                 sx={{
//                   '& .MuiDataGrid-columnHeader': {
//                     backgroundColor: 'Black',
//                     textAlign: 'center',
//                     fontSize: '18px',
//                     color: 'white',
//                     '& .MuiCheckbox-root': {
//                       color: 'white',
//                     },
//                   },
//                 }}
//               />
//             </div>
//           </>
//         ) : (
//           <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
//             Oh Snap! You aren't a premium user so you don't have access to the updating this information. If you think that's incorrect then reach out to sitesteer_ai@gmail.com.
//           </Typography>
//         )}
//       </div>
//       {/* Add User Popup */}
//       <Dialog open={AddPopup} onClose={() => setAddPopup(false)}>
//         <DialogTitle>Add Company Details</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Copmany Name"
//             value={newUser.name}
//             onChange={(e) => handleNewUserChange("name", e.target.value)}
//             fullWidth
//             margin="normal"
//             required
//           />
//           <TextField
//             label="Company Address"
//             value={newUser.email}
//             onChange={(e) => handleNewUserChange("email", e.target.value)}
//             fullWidth
//             margin="normal"
//             error={!!addEmailError}
//             helperText={addEmailError}
//             required
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setAddPopup(false)}>Cancel</Button>
//           <Button onClick={handleAddSubmit} color="primary">
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>
//       {/* Edit Users Popup */}
//       <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
//         <DialogTitle>Edit Details</DialogTitle>
//         <DialogContent>
//           <>
//             <TextField
//               label="Company Name"
//               value={editedUser.name}
//               onChange={(e) => handleEditChange("name", e.target.value)}
//               fullWidth
//               margin="normal"
//               required
//             />
//             <TextField
//               label="Company Address"
//               value={editedUser.organization_id}
//               onChange={(e) => handleEditChange("organization_id", [e.target.value])}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               required
//             >
//             </TextField>
//           </>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenEditPopup(false)}>Cancel</Button>
//           <Button onClick={handleEditSubmit} color="primary">Save</Button>
//         </DialogActions>
//       </Dialog>
//       {/* Delete Users Confirmation Dialog */}
//       <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
//         <DialogTitle>Confirm Remove</DialogTitle>
//         <DialogContent>
//           <Typography>Are you sure you want to remove the selected row?</Typography>
//           <FormControlLabel
//             control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
//             label="Confirm Delete"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
//           <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={showDeleteMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="User Removed"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//       <Snackbar
//         open={showEditMessage}
//         autoHideDuration={2000} //disappears after 3s
//         onClose={handleCloseSuccessMessage}
//         message="User Details Updated"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//     </div>
//   );
// };

// export default TeamManagement;


import React, { useState, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { CompanyFetch } from "../components/Backend Routes/fetchCompany";

const columns: GridColDef[] = [
  { field: "name", headerName: "Company Name", flex: 1.5, minWidth: 250 },
  { field: "address", headerName: "Company Address", flex: 3, minWidth: 300 },
  { field: "vat", headerName: "VAT Number", flex: 1.5, minWidth: 250 },
  { field: "email", headerName: "Email", flex: 1.5, minWidth: 300 },
  { field: "telephone", headerName: "Telephone", flex: 1.5, minWidth: 250 },
];

const CompanyDetails = () => {
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const { company, loading, error, refetchCompany } = CompanyFetch(premiumKey);
  
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [editedCompany, setEditedCompany] = useState({
    owner_org: premiumKey,
    name: "",
    address: "",
    vat: "",
    email: "",
    telephone: "",
  });
  const navigate = useNavigate();

  // const handleAddEdit = () => {
  //   setEditedCompany(company || {
  //     owner_org: premiumKey,
  //     name: "",
  //     address: "",
  //     vat: "",
  //     email: "",
  //     telephone: "",
  //   });
  //   setOpenAddEditPopup(true);
  // };
  const handleAddEdit = () => {
    setEditedCompany(company ? 
      { ...company, owner_org: premiumKey } : 
      {
        owner_org: premiumKey,
        name: "",
        address: "",
        vat: "",
        email: "",
        telephone: "",
      }
    );
    setOpenAddEditPopup(true);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleChange = (field, value) => {
    setEditedCompany(prevCompany => ({
      ...prevCompany,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    const companyToSubmit = {
      ...editedCompany,
    };
    console.log('updated company:', companyToSubmit);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(companyToSubmit),
      });
      if (!response.ok) {
        throw new Error('Failed to update company details');
      }
      await refetchCompany();
      setMessageText("Company details updated successfully");
      setShowMessage(true);
      setOpenAddEditPopup(false);
    } catch (error) {
      console.error("Error updating company details:", error);
      setMessageText("Failed to update company details");
      setShowMessage(true);
    }
  };


  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const returnProfile = () => {
    navigate('/profile');
  };

  const rows = company ? [{ id: 1, ...company }] : [];

  return (
    <div className="page-container">
      <Tooltip title="Back to Company Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Company Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <div className="company-details-container">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEdit}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                {company ? "Edit Company Details" : "Add Company Details"}
              </Button>
            </div>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={1}
                disableSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* Add/Edit Company Details Popup */}
      <Dialog open={openAddEditPopup} onClose={() => setOpenAddEditPopup(false)}>
        <DialogTitle>{company ? "Edit Company Details" : "Add Company Details"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Company Name"
            value={editedCompany.name}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Company Address"
            value={editedCompany.address}
            onChange={(e) => handleChange("address", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="VAT Number"
            value={editedCompany.vat}
            onChange={(e) => handleChange("vat", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={editedCompany.email}
            onChange={(e) => handleChange("email", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Telephone"
            value={editedCompany.telephone}
            onChange={(e) => handleChange("telephone", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddEditPopup(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showMessage}
        autoHideDuration={2000}
        onClose={handleCloseMessage}
        message={messageText}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default CompanyDetails;