// QuoteDialog.js
import React, { useState, useMemo, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  IconButton, 
  Typography, 
  CircularProgress,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Box,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm, useFieldArray, useWatch, Controller } from 'react-hook-form';

const unitsOptions = [
  { value: '/ hour', label: '/ hour' },
  { value: '/ project', label: '/ project' },
  { value: '/ day', label: '/ day' },
  { value: '/ unit', label: '/ unit' },
];

const QuoteDialog = ({ open, onClose, editedItem, handleSubmit: externalHandleSubmit, prospectData }) => {
  const methods = useForm({
    defaultValues: {
      companyId: editedItem?.companyId || '',
      projectId: editedItem?.projectId || '',
      quoteId: editedItem.quoteId || '',
      name: editedItem?.name || '',
      order_number: editedItem?.order_number || '',
      issue_date: editedItem?.issue_date ? editedItem.issue_date.split('T')[0] : '',
      status: editedItem?.status || 'Draft',
      lineItems: editedItem?.lineItems || [
        { lineItem: '', quantity: 1, units: '/ hour', pricePerUnit: 0.00 }
      ],
    }
  });
  
  const { register, control, watch, reset, setValue, formState: { errors } } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineItems',
  });
  
  const [isLoading, setIsLoading] = useState(false);

  // Initiating state of quoteTotal
  const [quoteTotal, setQuoteTotal] = useState(0);

  // New state for tracking the action
  const [action, setAction] = useState(''); 
  
  const watchLineItems = watch('lineItems');
  const watchCompanyId = watch('companyId');
  const watchProjectId = watch('projectId');

  // Effect to set initial values when editedItem changes
useEffect(() => {
  if (editedItem) {
    console.log('QuoteDialog - Resetting form with editedItem:', editedItem);
    reset({
      companyId: editedItem.companyId || '',
      quoteId: editedItem.quoteId || '',
      projectId: editedItem.projectId || '',
      name: editedItem.name || '',
      quote_number: editedItem.quote_number || '',
      order_number: editedItem.order_number || '',
      status: editedItem.status || 'Draft',
      issue_date: editedItem.issue_date ? editedItem.issue_date.split('T')[0] : '',
      lineItems: editedItem.lineItems || [
        { lineItem: '', quantity: 1, units: '/ hour', pricePerUnit: 0.00 }
      ],
    }, {
      keepErrors: true, // Retain validation errors when resetting
      keepDirty: true,  // Retain dirty state when resetting
      keepTouched: true, // Retain touched fields when resetting
    });

    // Log the form values after reset for debugging
    const subscription = methods.watch((value) => {
      console.log('QuoteDialog - Form Values after reset:', value);
    });
    
    // Cleanup subscription
    return () => subscription.unsubscribe();
  }
}, [editedItem, reset, methods]);

  // Helper function to safely parse numeric values
  const safeParseFloat = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  // Custom hook to watch all line items
  const LineItemsWatch = () => {
    const lineItems = useWatch({
      control,
      name: 'lineItems',
    });

    // Effect to update quote total when line items change
    useEffect(() => {
      const newTotal = lineItems.reduce((acc, item) => {
        if (item && item.quantity && item.pricePerUnit) {
          const lineTotal = safeParseFloat(item.quantity) * safeParseFloat(item.pricePerUnit);
          return acc + lineTotal;
        }
        return acc;
      }, 0);
      setQuoteTotal(newTotal);
    }, [lineItems]);

    return null;
  };

  // Find the selected prospect based on companyId and projectId
  const selectedProspect = useMemo(() => {
    return prospectData.find(prospect => 
      prospect.companyId === watchCompanyId && prospect.projectId === watchProjectId
    );
  }, [watchCompanyId, watchProjectId, prospectData]);

  
  const onSubmit = async (data, submittedAction) => {
    setIsLoading(true);
    

    try {
      // Combine form data with immutable data from selectedProspect
      const combinedData = {
        ...data,
        quoteTotal: quoteTotal,
        action: submittedAction // Include the action in the submitted data
      };
      console.log('quote data from QuoteDialog to Quotes', combinedData)
      await externalHandleSubmit(combinedData);
      onClose();
    } catch (error) {
      console.error("Error submitting quote:", error);
      // Optionally, handle error (e.g., show error message)
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleAddRow = () => {
    append({ lineItem: '', quantity: 1, units: '/ hour', pricePerUnit: 0.00 });
  };

  // function for resetting the data when closing the quote
  const handleClose = () => {
    reset();
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {editedItem && editedItem.id ? "Edit Quote" : "Add Quote"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => onSubmit(data, action))}>
            {/* Add this line to use the custom watch hook */}
            <LineItemsWatch />
            {/* Company Selection */}
            <Controller
              name="companyId"
              control={control}
              rules={{ required: "Company is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Company"
                  fullWidth
                  margin="normal"
                  error={!!errors.companyId}
                  helperText={errors.companyId?.message}
                >
                  {prospectData.map((prospect) => (
                    <MenuItem key={prospect.companyId} value={prospect.companyId}>
                      {prospect.companyName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {/* Project Selection */}
            <Controller
              name="projectId"
              control={control}
              rules={{ required: "Project is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Project"
                  fullWidth
                  margin="normal"
                  error={!!errors.projectId}
                  helperText={errors.projectId?.message}
                  disabled={!watchCompanyId}
                >
                  {prospectData
                    .filter((prospect) => prospect.companyId === watchCompanyId)
                    .map((prospect) => (
                      <MenuItem key={prospect.projectId} value={prospect.projectId}>
                        {prospect.projectName}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />

            {/* Immutable Fields Section */}
            <Box mb={4}>
              <Grid container spacing={2}>
                {/* First Column */}
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Telephone
                  </Typography>
                  <Typography variant="body1">
                    {selectedProspect?.telephone || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <Typography variant="subtitle2" color="textSecondary">
                    Quote Number
                  </Typography>
                  <Typography variant="body1">
                    {editedItem?.quoteNumber || 'N/A'}
                  </Typography> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    VAT Number
                  </Typography>
                  <Typography variant="body1">
                    {selectedProspect?.vat_number || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Company Number
                  </Typography>
                  <Typography variant="body1">
                    {selectedProspect?.company_number || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Site Address
                  </Typography>
                  <Typography variant="body1">
                    {selectedProspect?.site_address || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Company Address
                  </Typography>
                  <Typography variant="body1">
                    {selectedProspect?.company_address || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Quote Name */}
            <TextField
              label="Quote Name"
              {...register('name', { required: "Quote Name is required" })}
              fullWidth
              margin="normal"
              required
              error={!!errors.name}
              helperText={errors.name?.message}
            />

            {/* Quote Number */}
            <TextField
              label="Quote Number"
              {...register('quote_number')}
              fullWidth
              margin="normal"
            />

            {/* Order Number */}
            <TextField
              label="Order Number"
              {...register('order_number')}
              fullWidth
              margin="normal"
            />

          {/* Issue Date */}
          <Controller
              name="issue_date"
              control={control}
              rules={{ required: "Issue Date is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  label="Issue Date"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.issue_date}
                  helperText={errors.issue_date?.message}
                />
              )}
            />

            {/* Status Selection */}
            <Controller
              name="status"
              control={control}
              rules={{ required: "Status is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Quote Status"
                  fullWidth
                  margin="normal"
                  error={!!errors.status}
                  helperText={errors.status?.message}
                >
                  <MenuItem value="Draft">Draft</MenuItem>
                  <MenuItem value="Created">Created</MenuItem>
                  <MenuItem value="Issued">Issued</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                </TextField>
              )}
            />
            
            {/* Dynamic Line Items Table */}
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Quote Breakdown
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Line Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Units</TableCell>
                      <TableCell>Price Per Unit (£)</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Controller
                            name={`lineItems.${index}.lineItem`}
                            control={control}
                            rules={{ required: "Line Item is required" }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="standard"
                                fullWidth
                                error={!!errors.lineItems?.[index]?.lineItem}
                                helperText={errors.lineItems?.[index]?.lineItem?.message}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`lineItems.${index}.quantity`}
                            control={control}
                            rules={{ 
                              required: "Quantity is required", 
                              min: { value: 0, message: "Quantity cannot be negative" },
                              valueAsNumber: true
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                inputProps={{ min: 0, step: 1 }}
                                variant="standard"
                                fullWidth
                                error={!!errors.lineItems?.[index]?.quantity}
                                helperText={errors.lineItems?.[index]?.quantity?.message}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`lineItems.${index}.units`}
                            control={control}
                            rules={{ required: "Units are required" }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                select
                                variant="standard"
                                fullWidth
                                error={!!errors.lineItems?.[index]?.units}
                                helperText={errors.lineItems?.[index]?.units?.message}
                              >
                                {unitsOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`lineItems.${index}.pricePerUnit`}
                            control={control}
                            rules={{ 
                              required: "Price is required", 
                              min: { value: 0, message: "Price cannot be negative" },
                              pattern: { value: /^\d+(\.\d{1,2})?$/, message: "Invalid price format" },
                              valueAsNumber: true
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                inputProps={{ min: 0, step: 0.01 }}
                                variant="standard"
                                fullWidth
                                error={!!errors.lineItems?.[index]?.pricePerUnit}
                                helperText={errors.lineItems?.[index]?.pricePerUnit?.message}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>
                            £{(safeParseFloat(watchLineItems[index]?.quantity) * 
                              safeParseFloat(watchLineItems[index]?.pricePerUnit)).toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button 
                            color="secondary" 
                            onClick={() => remove(index)} 
                            disabled={fields.length === 1}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {/* Table Footer for Quote Total */}
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        <Typography variant="subtitle1" fontWeight="bold">
                          Quote Total:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          £{quoteTotal.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Box mt={2}>
                <Button variant="outlined" onClick={handleAddRow}>
                  Add Row
                </Button>
              </Box>
            </Box>
            
            {/* Hidden Submit Button to trigger form submission */}
            <button type="submit" style={{ display: 'none' }}></button>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>Cancel</Button>
        <Button 
          onClick={() => {
            setAction('draft');
            methods.handleSubmit((data) => onSubmit(data, 'draft'))();
          }}
          color="primary" 
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Save Draft'}
        </Button>
        <Button 
          onClick={() => {
            setAction('finalize');
            methods.handleSubmit((data) => onSubmit(data, 'finalize'))();
          }}
          color="primary" 
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Finalize Quote'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoteDialog;


